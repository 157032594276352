import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, Redirect, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from '../redux/actions/setAppActions'
import { Alert } from '../components/Alert'


const initialState = {
    email: '',
    password: '',
}

export const Login = ({ role }) => {
    const dispatch = useDispatch()
    const classId = useParams().classId
    const [group, setGroup] = useState(null)
    const { alert } = useSelector(state => state.app)
    const [redirect, setRedirect] = useState(false)
    const [form, setForm] = useState(initialState)

    useEffect(() => {
        if (role === 'pupil' && classId) {
            (async () => {
                const { data } = await axios.get(`classes/${classId}`)
                setGroup(data)
            })()
        }
    }, [role, classId])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const login = async () => {
        try {
            const body = { ...form }
            if (group && classId) {
                body.classId = classId
                body.psychId = group?.psych?._id
            }
            const { data } = await axios.post(`/${role}/login`, body)
            dispatch({ type: 'AUTH/SET_USER', payload: data })
            setRedirect(true)
        } catch (e) {
            if (e.response.data.message) {
                dispatch(showAlert({ type: 'error', text: e.response.data.message }))
            } else {
                dispatch(showAlert({ type: 'error', text: 'Некорректные данные. Или вы не верифицировали почту' }))
            }
        }
    }

    if (redirect) {
        if (document.referrer.includes("constructor")) {
            window.location = "http://constructor.testu.online"
            return
        }
        return <Redirect to={'/dashboard'} />
    }

    return (
        <div className='main'>
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">
                        {role === 'pupil' ? ' Вход ученика' : 'Вход психолога'}
                    </p>
                </header>

                <div className="auth-page__wrapper">
                    <div className="auth-page__header">
                        <h3>Вход</h3>
                        {role === 'psych' && <Link
                            to={'/psych/register'}
                            className="auth-page__header-btn">
                            Нет аккаунта?
                        </Link>}
                    </div>
                    <div className="auth-page__input-group">
                        <div className="input-field">
                            <input
                                id="email"
                                type="text"
                                name="email"
                                value={form.email}
                                placeholder="Логин"
                                onChange={changeHandler}
                            />
                            <label htmlFor="email">
                                Логин
                            </label>
                        </div>
                        <div className="input-field">
                            <input
                                id="password"
                                type="password"
                                name="password"
                                value={form.password}
                                placeholder="Введите пароль"
                                onChange={changeHandler}
                            />
                            <label htmlFor="password">
                                Введите пароль
                            </label>
                        </div>
                        <Link to={`/${role}/lose`}>
                            Забыли пароль?
                        </Link>
                    </div>
                    <button
                        onClick={login}
                        className="main-btn auth-page__btn">
                        Войти
                    </button>
                </div>
            </div>
        </div>
    )
}
