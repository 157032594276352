import React, {useCallback, useState, useEffect, useRef} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Chart} from 'chart.js'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {BackButton} from '../components/BackButton'
import axios from 'axios'
import {Layout} from '../components/Layout'
import '../static/scss/scaleDesc.scss'
import ic_info from "../static/img/ic-info-black.svg"


export const SolutionPage = () => {
    const {role} = useSelector(state => state.auth)
    const [test, setTest] = useState(null)
    const [html, setHtml] = useState(null)
    const [info, setInfo] = useState(null)
    const [scaleDesc, setScaleDesc] = useState(null)
    const [diagram, setDiagram] = useState(false)
    const [isDocPage, setIsDocPage] = useState(false)
    const [rows, setRows] = useState([])
    const [isTable1, setIsTable1] = useState(false);
    const [isTable2, setIsTable2] = useState(false);
    const [isTexts, setIsTexts] = useState(false);
    const [currentText, setCurrentText] = useState({});
    let history = useHistory();
    const testId = useParams().id


    function sortedScales(scales){
        let newScales = [[],[],[],[],[]];

        for(let i = 0; i < scales.length; i++){
            switch (scales[i].level){
                case 1:
                    newScales[0].push(scales[i])
                    break;
                case 2:
                    newScales[1].push(scales[i])
                    break;
                case 3:
                    newScales[2].push(scales[i])
                    break;
                case 4:
                    newScales[3].push(scales[i])
                    break;
                case 5:
                    newScales[4].push(scales[i])
                    break;
                default:
                    break;
            }
        }
        for(let i = 0; i < newScales.length; i++){
            newScales[i].sort((a, b) => a.score - b.score)
        }
        return [...newScales[0], ...newScales[1], ...newScales[2], ...newScales[3], ...newScales[4]]
    }

    const getTest = useCallback(async () => {
        try {
            const {data} = await axios.get(`/tests/${testId}`)
            setTest(data)
        } catch (e) {
            console.log(e)
        }
    }, [testId])

    useEffect(() => {
        getTest()
    }, [getTest])

    useEffect(() => {
        if (test) {
            setRows(sortedScales(test.solution.results))

            const html = "<html></html>"
            const parser = new DOMParser()
            const document = parser.parseFromString(html, 'text/html')
            setHtml(document)
        }
    }, [test])

    const yearsOldCounter = useCallback((birthday) => {
        const nowDate = Date.now()
        const birthdayDate = new Date(birthday)
        const days = (nowDate - birthdayDate) / (60 * 60 * 24 * 1000)
        return `${Math.floor(days / 365)}`
    }, [])

    const docBtnHandler = useCallback(() => {
        setIsDocPage(!isDocPage)
    }, [isDocPage])

    const diagramBtnHandler = useCallback(() => {
        setDiagram(!diagram)
    }, [diagram])

    const infoHandler = (e) => {

        return setInfo(rows[e.currentTarget.id])
    }
    const scaleDescHandler = (e) => {
        return setScaleDesc(rows[e.target.id])
    }

    if (!html) {
        return null
    }

    if(isTexts){
        return(
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {setIsTexts(false);}}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    </header>
                    <h3>{currentText.question}</h3>
                    <label style={{opacity: 0.3}}>Ответ</label>
                    <p>{currentText.answer}</p>
                </div>
            </Layout>
        )
    }
    if(isTable1){
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {setIsTable1(false)}}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    </header>
                    <h3>Таблица 1</h3>
                    <div className="questions-list">
                        {test.solution.first.map((item, itemOrder) => {
                            return(
                                <div className="questions-list__item">
                                    <div className="number-holder">
                                        <p>Вопрос {itemOrder + 1}</p>
                                    </div>
                                    <p>{item.answer}</p>
                                    <label>{item.question}</label>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </Layout>
        )
    }
    if(isTable2) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {setIsTable2(false)}}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    </header>
                    <h3>Таблица 2</h3>
                    <div className="questions-list">
                        {test.solution.second.map((item, itemOrder) => {
                            return(
                                <div className="questions-list__item">
                                    <div className="number-holder">
                                        <p>Вопрос {itemOrder + 1}</p>
                                    </div>
                                    <p>{item.answer}</p>
                                    <label>{item.question}</label>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </Layout>
        )
    }
    if (scaleDesc) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {setScaleDesc(null); setInfo(null)}}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"/>
                        <p className="page__title">Описание шкалы</p>
                    </header>
                    <div className="scale-desc" style={{flexGrow: 1}}>
                        <h3>{info.name}</h3>
                        <h4>Описание шкалы</h4>
                        <p>{info.info}</p>
                        <h4>Уровни шкалы:</h4>

                        <div className="scale-desc__levels">
                            {info.levels ? info.levels.map((level, id1) => {
                                return(
                                    <div className="scale-desc__level">
                                        <p>от {level.from} до {level.to}</p>
                                        <p><span>Уровень {id1 + 1}</span></p>
                                    </div>
                                )
                            }) : ''}
                        </div>
                        {/* <p dangerouslySetInnerHTML={{ __html: desc }} /> */}
                    </div>
                </div>
            </Layout>
        )
    }
    if (info) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => setInfo(null)}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"/>
                        <p className="page__title">Описание шкалы</p>
                    </header>
                    <div className="scale-desc" style={{flexGrow: 1}}>
                        <h3>{info.name}</h3>
                        <h5>Результат: {info.score}</h5>
                        <h4>Описание шкалы</h4>
                        <p>{info.info}</p>
                        <h4>Уровень {info.level}</h4>
                        <p>{info.interpretation}</p>
                        <h4>Рекомендации:</h4>
                        <p>{info.recommendation}</p>
                        {/* <p dangerouslySetInnerHTML={{ __html: desc }} /> */}
                    </div>
                </div>
            </Layout>
        )
    }

    if (isDocPage) {
        return <DocPage test={test} handler={docBtnHandler} doc={html}/>
    }

    if (diagram) {
        return <Diagram handler={diagramBtnHandler} rows={rows} conditionName={test.condition.name} test={test}/>
    }

    function returnRightWordForYears(val){
        if(val <= 12){
            if(val === 1){
                return 'год'
            } else if (val >= 2 && val <= 4 ){
                return 'года'
            } else {
                return 'лет'
            }
        } else {
            if(val % 10 === 1){
                return 'год'
            } else if (val % 10 >= 2 && val % 10 <= 4 ){
                return 'года'
            } else {
                return 'лет'
            }
        }
    }

    const goBack = () => history.goBack()
    console.log(test.pupil)
    return (
        <Layout>
            <div className='page solution-page page__small'>
                <header className="page__header">
                    <BackButton/>
                    <a onClick={goBack} className="icon-btn page__icon-btn page__icon-btn_right icon-btn_close"/>
                    <p className="page__title solution-page__title">Результаты</p>
                </header>

                <div className="solution-page__main">
                    <div className="solution-page__info">
                        <p className="solution-page__name">{test.pupil.name} {test.pupil.surname}</p>
                        {test.pupil.birthday ?
                            <p className="solution-page__birthday">{yearsOldCounter(test.pupil.birthday)} {returnRightWordForYears(yearsOldCounter(test.pupil.birthday))}, {test.schoolName}, {test.city} </p> : null}
                    </div>
                    {role === 'psych' && <div className="solution-page__contacts">
                        <Link
                            to={`/pupils/${test.pupil._id}`}
                            className="profile-page__btn">
                            Перейти в профиль
                        </Link>
                    </div>}
                </div>

                <div className="solution-results">
                    <div className="solution-results__wrapper">
                        <p className="solution-results__title">Результаты теста</p>
                        <p className="solution-results__name">{test.condition.name}</p>


                        <p style={{fontSize: '16px', marginBottom: '0px'}} className="solution-results__name">Описание для специалиста</p>
                        <p className="solution-results__desc" dangerouslySetInnerHTML={{__html: test.condition.desTeacher}}/>

                        <p style={{fontSize: '16px', marginBottom: '0px'}} className="solution-results__name">Инструкция для ученика</p>
                        <p style={{marginBottom: '40px'}} className="solution-results__desc" dangerouslySetInnerHTML={{__html: test.condition.message}}/>

                        {test.solution.first.length > 0 ?  <span style={{marginBottom: '10px'}} onClick={() => {setIsTable1(true)}}
                                                      className="solution-results__doc-btn">Таблица 1</span> : ''}
                        {test.solution.text ?
                            test.solution.text.length > 0 ?
                                test.solution.text.map((text, textOrder) => {
                                    return <span style={{marginBottom: '10px'}} onClick={() => {setIsTexts(true); setCurrentText(text)}}
                                          className="solution-results__doc-btn"><p>{test.condition.name === 'Родительская анкета' ? 'Описание происшествия' : text.question}</p></span>
                                })
                                :''
                            : ''}
                        {test.solution.second.length > 0 ?  <span style={{marginBottom: '10px'}} onClick={() => {setIsTable2(true)}}
                                                      className="solution-results__doc-btn">Таблица 2</span> : ''}
                        <span onClick={diagramBtnHandler}
                              className="solution-results__doc-btn">Диаграмма</span>
                        <div className="solution-results__items">
                            {rows ? rows.map((row, index) =>
                                <div onClick={infoHandler} className="solution-result" id={index}>
                                    <button
                                        id={index}
                                        className="list__info-btn"
                                        onClick={scaleDescHandler}/>
                                    <p className="solution-result__title">{row.name}</p>
                                    <div className="solution-result__row">
                                        <span className="solution-result__row-title">{row.method}:</span>
                                        <span>{row.score}</span>
                                    </div>
                                    <div className="solution-result__row">
                                        <span className="solution-result__row-title">Показатель:</span>
                                        <span style={{background: row.color}}
                                              className="solution-result__mark">{row.title}</span>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const DocPage = ({handler, doc, test}) => {
    const [document, setDocument] = useState(null)

    useEffect(() => {
        const parser = new DOMParser()
        const html = doc.body.innerHTML
        if (html !== "") {
            const document = parser.parseFromString(html, 'text/html')
            document.querySelector('body p:last-child').remove()
            var ns = new XMLSerializer()
            var ss = ns.serializeToString(document)
            setDocument(ss)
        }
    }, [doc])

    return (
        <Layout>
            <div className='page'>
                <header className="page__header">
                    <button
                        onClick={handler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_close"/>
                    <p className="page__title">Информация о тесте</p>
                </header>
                <div className="solution-results">
                    <div className="solution-results__wrapper">
                        <p className="solution-results__name">{test.condition.name}</p>
                        <p className="solution-results__desc" dangerouslySetInnerHTML={{__html: test.condition.desc}}/>
                        <div className="solution-results__scales">
                            {test ? test.solution.length > 0 ? test.solution.map((item) => {
                                return(
                                    <div className="solution-results__scale">
                                        <label>01</label>
                                        <h3>{item.name} <img alt="" src={ic_info}/></h3>
                                        <h5>Имеет шкалу от {item.levels ? item.levels[0].from : ''} до {item.levels ? item.levels[item.levels.length - 1].to : ''} условных балов.</h5>
                                        <div className="scale-desc__levels">
                                            {item.levels ? item.levels.map((level, id1) => {
                                                return(
                                                    <div className="scale-desc__level">
                                                        <p>от {level.from} до {level.to}</p>
                                                        <p><span>Уровень {id1 + 1}</span></p>
                                                    </div>
                                                )
                                            }) : ''}
                                        </div>
                                    </div>
                                )
                            }) : '' : ''}

                        </div>
                    </div>
                </div>
                <Link style={{bottom: '100px'}} to={`/tests/${test._id}/${test.condition.body.conditionId}`} className="main-btn">
                    Посмотреть вопросы
                </Link>
                <div dangerouslySetInnerHTML={{__html: document}}/>

            </div>

        </Layout>
    )
}


export const Diagram = ({handler, conditionName, rows}) => {
    const canvasRef = useRef(null)
    const [row, setRow] = useState(null)
    const [data, setData] = useState(null)
    const [colors, setColors] = useState(null)
    const [radar, setRadar] = useState(null)


    useEffect(() => {
        setRow(rows[0])
    }, [rows])

    useEffect(() => {
        const newLabels = []
        const newData = []
        const colors = []

        for (let item of rows) {
            newLabels.push('•')
            newData.push(Number(item.score.replace(',', '.')))

            if (item.norm) {
                colors.push(row === item ? 'rgba(82,194,43, 1)' : 'rgba(82,194,43, 0.5)')
            } else {
                colors.push(row === item ? 'rgba(255,90,82, 1)' : 'rgba(255,90,82, 0.5)')
            }
        }

        const data = {
            labels: newLabels,
            datasets: [
                {
                    fill: false,
                    data: newData,
                    borderWidth: 2,
                    borderColor: '#52C22B'
                },
            ],
        }
        setData(data)
        setColors(colors)


    }, [rows, row])

    useEffect(() => {
        const canvas = canvasRef.current

        const options = {
            maintainAspectRatio: false,
            scale: {
                pointLabels: {
                    fontSize: 45,
                    fontColor: colors,
                },
                ticks: {
                    min: 0,
                    stepSize: 1,
                    display: false
                },
            },

            layout: {
                padding: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }
            },
            legend: {display: false},
            tooltips: {enabled: false},
            // onClick: function (evt, element) {
            //     if (!element.length) return
            //     setRow(rows[element[0]._index])
            // },
        }

        if (canvas) {
            const context = canvas.getContext('2d')
            const myRadar = new Chart(context, {
                type: 'radar',
                data: data,
                options: options,
            })
            myRadar.ctx.minHeight = 275
            // myRadar.ctx.width = '100%'

            setRadar(myRadar)
        }

    }, [data, rows, colors])

    const canvasHandler = (e) => {
        var helpers = Chart.helpers
        var scale = radar.scale
        var opts = scale.options
        var tickOpts = opts.ticks

        // Position of click relative to canvas.
        var mouseX = e.nativeEvent.offsetX
        var mouseY = e.nativeEvent.offsetY

        var labelPadding = 5 // number pixels to expand label bounding box by

        var tickBackdropHeight = (tickOpts.display && opts.display) ?
            helpers.valueOrDefault(tickOpts.fontSize, Chart.defaults.global.defaultFontSize)
            + 5 : 0
        var outerDistance = scale.getDistanceFromCenterForValue(opts.ticks.reverse ? scale.min : scale.max)
        for (var i = 0; i < scale.pointLabels.length; i++) {
            // Extra spacing for top value due to axis labels
            var extra = (i === 0 ? tickBackdropHeight / 2 : 0)
            var pointLabelPosition = scale.getPointPosition(i, outerDistance + extra + 5)

            var plSize = scale._pointLabelSizes[i]

            // get label textAlign info
            var angleRadians = scale.getIndexAngle(i)
            var angle = helpers.toDegrees(angleRadians)
            var textAlign = 'right'
            if (angle === 0 || angle === 180) {
                textAlign = 'center'
            } else if (angle < 180) {
                textAlign = 'left'
            }

            var verticalTextOffset = 0
            if (angle === 90 || angle === 270) {
                verticalTextOffset = plSize.h / 2
            } else if (angle > 270 || angle < 90) {
                verticalTextOffset = plSize.h
            }

            var labelTop = pointLabelPosition.y - verticalTextOffset - labelPadding;
            var labelHeight = 2 * labelPadding + plSize.h
            var labelBottom = labelTop + labelHeight

            var labelWidth = plSize.w + 2 * labelPadding
            var labelLeft
            switch (textAlign) {
                case 'center':
                    labelLeft = pointLabelPosition.x - labelWidth / 2
                    break
                case 'left':
                    labelLeft = pointLabelPosition.x - labelPadding
                    break;
                case 'right':
                    labelLeft = pointLabelPosition.x - labelWidth + labelPadding
                    break
                default:
                    console.log('ERROR: unknown textAlign ' + textAlign)
            }
            var labelRight = labelLeft + labelWidth

            if (mouseX >= labelLeft && mouseX <= labelRight && mouseY <= labelBottom && mouseY >= labelTop) {
                setRow(rows[i])
                break
            }


        }
    }

    if (!row) return null

        return (
                    <Layout>
                <div className='page'>
                <header className="page__header">
                <button onClick={handler}
            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_close"></button>
            <p className="page__title">Статистика</p>
        </header>

            <div className="diagram">
                <p className='diagram__title'>{conditionName}</p>


                 {/*<Radar data={data} options={options}*/}
                 {/*           getElementAtEvent={getElementAtEvent}*/}
                 {/*       />*/}

                <div className="chart-container"
                     style={{'minHeight': `278px`, 'width': '100%', 'position': 'relative'}}>
                    <canvas ref={canvasRef} onClick={canvasHandler}/>
                </div>

                <p className="diagram__condition-name">{row.name}</p>

                <div className="diagram__details">
                    <p className="diagram__details-key">{row.method}</p>
                    <p className="diagram__details-value">{row.score}</p>
                </div>

                <div className="diagram__details">
                    <p className="diagram__details-key">Показатель:</p>
                    <span style={{background: row.color}} className="solution-result__mark">{row.title}</span>
                </div>


                    <p className="diagram__condition-name">Интерпретация:</p>
                    <p>{row.interpretation}</p>
                    <p className="diagram__condition-name">Рекомендации:</p>
                    <p>{row.recommendation}</p>

            </div>
        </div>
        </Layout>
        )


    // return (
    //
    // <div className="diagram">
    //     {/*<p className='diagram__title'>{conditionName}</p>*/}
    //
    //
    //     {/* <Radar data={data} options={options}
    //                 getElementAtEvent={getElementAtEvent}
    //             /> */}
    //
    //     <div className="chart-container"
    //          style={{'minHeight': `278px`, 'width': '100%', 'position': 'relative'}}>
    //         <canvas ref={canvasRef} onClick={canvasHandler}/>
    //     </div>
    //
    //     {/*<p className="diagram__condition-name">{row.name}</p>*/}
    //
    //     {/*<div className="diagram__details">*/}
    //     {/*    <p className="diagram__details-key">{row.method}</p>*/}
    //     {/*    <p className="diagram__details-value">{row.score}</p>*/}
    //     {/*</div>*/}
    //
    //     {/*<div className="diagram__details">*/}
    //     {/*    <p className="diagram__details-key">Показатель:</p>*/}
    //     {/*    <span style={{background: row.color}} className="solution-result__mark">{row.title}</span>*/}
    //     {/*</div>*/}
    //
    // </div>
    // )
}
