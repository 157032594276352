import React from "react";
import '../static/scss/freemiumBanner.scss'
import {FaCross} from "react-icons/all";
import cross from '../static/img/ic-close.svg';
import {Link} from "react-router-dom";

export default function FreemiumBanner(props){
    return(
        <div className="freemium-banner">
            <h3 className="freemium-banner__h3">Спасибо за интерес к нашему продукту!</h3>
            <h4 className="freemium-banner__h4">Мы предлагаем бесплатно попробовать наше решение для проведения психодиагностики в Вашем учреждении
            </h4>
            <h4 className="freemium-banner__h4 freemium-banner__h4-blue">Вы получите:</h4>
            <div className="freemium-banner__row">
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">
                        01
                    </div>
                    <p className="freemium-banner__p">Проблемно-ориентированный комплекс из 18 валидированных методик</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">
                        02
                    </div>
                    <p className="freemium-banner__p">Система рекомендаций по результатам тестирования и интерпретация каждого психометрического показателя</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">
                        03
                    </div>
                    <p className="freemium-banner__p">Конструктор тестов для проведения собственных анкетирований учащихся</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">
                        04
                    </div>
                    <p className="freemium-banner__p">Ведение заметок по учащимся, функционал назначения встреч, поддержка обратной связи</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">
                        05
                    </div>
                    <p className="freemium-banner__p">Автоматизированная обработка результатов психодиагностики и формирование отчетов</p>
                </div>
                <div className="freemium-banner__point">
                    <div className="freemium-banner__circle">
                        06
                    </div>
                    <p className="freemium-banner__p">Возможность проводить массовые опросы во всем классе в течении 10 минут с получением результатов</p>
                </div>
            </div>
            <div className="freemium-banner__rowBottom">
                <Link to={'/psych/register'} className="freemium-banner__btn">Регистрация</Link>
                <h5 className="freemium-banner__h5">
                    С Заботой о будущем поколении!<br/>
                    Команда TestU.online</h5>
            </div>
            <button onClick={() => {props.showPopup()}} className="freemium-banner__close">
                <img src={cross}/>
            </button>
        </div>
    )
}