import { useEffect, useState } from 'react'
import axios from 'axios'
import Moment from 'react-moment'
import 'moment/locale/ru'
import { useSelector } from "react-redux"
import { Link, useParams } from 'react-router-dom'
import { BackButton } from "../../components/BackButton"
import { Layout } from "../../components/Layout"
import { Range } from "../../components/Range"


export const StatisticPupil = () => {
    const pupilId = useParams().id
    const [norm, setNorm] = useState(0)
    const [tests, setTests] = useState([])
    const [notNorm, setNotNorm] = useState(0)
    const [nullNorm, setNullNorm] = useState(0)
    const { filters } = useSelector(state => state.statistic)

    useEffect(() => {
        if (pupilId) {
            const arr = []

            if (filters.date) {
                arr.push(`date=${filters.date}`)
            }
            if (filters.condition) {
                arr.push(`condition=${filters.condition}`)
            }

            (async () => {
                const { data } = await axios.get(`statistic/pupils/${pupilId}?${arr.join('&')}`)
                setTests(data)
            })()
        }
    }, [pupilId, filters])

    useEffect(() => {
        if (tests.length) {
            const empty = tests.filter(test => test._id === null)[0]?.total
            const not = tests.filter(test => test._id === false)[0]?.total
            const norm = tests.filter(test => !!test._id)[0]?.total

            setNotNorm(Number(not || !!not))
            setNorm(Number(norm || !!norm))
            setNullNorm(Number(empty || !!empty))
        }
    }, [tests])

    return (
        <Layout>
            <div className="page statistic-details-page">
                {/* <Tools tools={[
                    { url: '/', title: 'Поделиться статистикой' },
                    {
                        action: () => setIsSelectScreen(true),
                        title: 'Открыть результаты'
                    }
                ]} /> */}
                <header className="page__header">
                    <BackButton />
                    <p className="page__title statistic-details-page__title">
                        Статистика
                    </p>
                    <Link
                        to={`/statistic/pupils/${pupilId}/filters`}
                        className={!!Object.values(filters).filter(val => !!val.length).length ? `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_pupil icon-btn_filter_active` : `icon-btn page__icon-btn icon-btn_filter_pupil page__icon-btn_right icon-btn_filter`}>
                        {!!Object.values(filters).filter(val => !!val.length).length && <span>
                            {Object.values(filters).filter(val => !!val.length).length}
                        </span>}
                    </Link>

                </header>

                <div className="statistic-details-page__main">
                    <div className="statistic-condition" >
                        <p className="statistic-condition__title">
                            {tests[0]?.pupil.name} {tests[0]?.pupil.surname}
                        </p>
                        <Range
                            total={norm + nullNorm}
                            norm={norm}
                            notNorm={nullNorm}
                        />
                        <div className="statistic-condition__footer">
                            <span className="statistic-condition__indicator green">
                                {norm}
                            </span>
                            {/*<span className="statistic-condition__indicator red">*/}
                            {/*    {notNorm}*/}
                            {/*</span>*/}
                            <span className="statistic-condition__indicator blue">
                                {nullNorm}
                            </span>
                            <p className="statistic-condition__members">
                                {norm + nullNorm} тестов
                            </p>
                        </div>
                    </div>
                </div>

                <div className="statistic-pupils">
                    <div className="statistic-pupils__wrapper">
                        {/*<div className="statistic-pupils__group">*/}
                        {/*    <div className="statistic-pupils__header">*/}
                        {/*        <p className="statistic-pupils__title red">*/}
                        {/*            Значимость не высокая*/}
                        {/*        </p>*/}
                        {/*        <span className="statistic-pupils__count">*/}
                        {/*            {notNorm}*/}
                        {/*        </span>*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        {tests.filter(test => test._id === false)[0]?.tests.map(test =>*/}
                        {/*            <Test key={test._id} test={test} />*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="statistic-pupils__group">
                            <div className="statistic-pupils__header">
                                <p className="statistic-pupils__title green">
                                    Проходили тест
                                </p>
                                <span className="statistic-pupils__count">
                                    {norm}
                                </span>
                            </div>
                            <div>
                                {tests.filter(test => !!test._id)[0]?.tests.map(test =>
                                    <Test key={test._id} test={test} />
                                )}
                            </div>
                        </div>

                        <div className="statistic-pupils__group">
                            <div className="statistic-pupils__header">
                                <p className="statistic-pupils__title blue">
                                    Не проходили тест
                                </p>
                                <span className="statistic-pupils__count">
                                    {nullNorm}
                                </span>
                            </div>
                            <div>
                                {tests.filter(test => test._id === null)[0]?.tests.map(test =>
                                    <div
                                        key={test._id}
                                        className="statistic-pupils__item">
                                        <div>
                                            <p className="statistic-pupils__name">
                                                {test.condition}
                                            </p>
                                            <p className="statistic-pupils__details">
                                                <Moment
                                                    locale="ru"
                                                    format="HH:mm, DD MMM, YYYY">
                                                    {test.date}
                                                </Moment>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


const Test = ({ test }) =>
    <Link
        to={`/solutions/${test._id}`}
        className="statistic-pupils__item">
        <div>
            <p className="statistic-pupils__name">
                {test.condition}
            </p>
            <p className="statistic-pupils__details">

                <Moment
                    locale="ru"
                    format="HH:mm, DD MMM, YYYY">
                    {test.date}
                </Moment>
            </p>
        </div>
    </Link>