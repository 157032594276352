import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import QRCode from "qrcode.react"
import axios from 'axios'
import { Layout } from '../components/Layout'
import { BackButton } from '../components/BackButton'


export const Code = () => {
    const classId = useParams().id
    const [data, setData] = useState(null)
    const [isChild, setIsChild] = useState(false);
    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`classes/${classId}`)
            setData(data)
        })()
    }, [classId])

    const shortUrl = useCallback(string => {
        const size = 24
        if (string.length > size) {
            return string.slice(0, size) + '...'
        }
        return string
    }, [])

    const copyHandler = () => {
        var field = document.createElement('textarea')
        field.innerText = `${window.location.host}/pupil/${classId}` + (isChild ? '/child' : '/parent')
        document.body.appendChild(field)
        field.select()
        document.execCommand('copy')
        field.remove()
        alert('Ссылка скопирована')
    }


    return (
        <Layout>
            <div className="page code-page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title code-page__title">
                        {data?.school.name}, {data?.number}{data?.letter}
                    </p>
                </header>
                <div className="auth-page__input-group auth-page__input-group_row">
                    <div className="input-field">
                        <input id="parents" type="radio" checked={!isChild}
                               name="parents" value='1' onChange={() => {setIsChild(false)}} />
                        <label htmlFor="parents">Родителям</label>
                    </div>
                    <div className="input-field">
                        <input id="childs" type="radio" checked={isChild}
                               name="childs" value='2' onChange={() => {setIsChild(true)}} />
                        <label htmlFor="childs">Детям</label>
                    </div>
                </div>
                <div className="page__content code-page__content">
                    <div className="code">
                        <QRCode
                            id="canvas"
                            size={234}
                            renderAs={"svg"}
                            value={`http://${window.location.host}/pupil/${classId}` + (isChild ? '/child' : '/parent')}
                            includeMargin={true}
                        />
                    </div>
                    <p>Отсканируйте QR-Code</p>
                </div>

                <div className="code-page__footer">
                    <p className="code-page__footer-title">
                        {shortUrl(`${window.location.host}/pupil/${classId}` + (isChild ? '/child' : '/parent'))}
                    </p>
                    <p className="code-page__footer-desc">
                        Ссылка регистрации ученика
                    </p>
                    <button onClick={copyHandler} className="code-page__copy-btn" />
                </div>
            </div>
        </Layout>
    )
}