import React, { useEffect } from 'react'
import Moment from 'react-moment'
import 'moment/locale/ru'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton } from '../../components/BackButton'
import { getMeetings, readMeetings } from '../../redux/actions/setMeetingActions'
import { Layout } from '../../components/Layout'


export const Meetings = () => {
    const dispatch = useDispatch()
    const { pupilId } = useParams()
    const { meetings } = useSelector(state => state.meeting)
    const { role } = useSelector(state => state.auth)
    const max = 70

    useEffect(() => {
        dispatch(getMeetings(pupilId))

        if (role === 'pupil') dispatch(readMeetings())

    }, [dispatch, pupilId, role])

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    {role === 'psych' && <Link
                        to={pupilId ? `/pupils/${pupilId}/meetings/create` : '/meetings/schools'}
                        className="icon-btn  page__icon-btn page__icon-btn_right icon-btn_add"
                    />}
                    <p className="page__title">Встречи</p>
                </header>
                <div className="school-page__wrapper">
                    <div className="list">
                        {meetings.map(meeting =>
                            <div key={meeting._id} className="list__item-wrap">
                                <p className="list__item-date">
                                    <Moment locale="ru" format="HH:mm, DD MMM, YYYY">
                                        {meeting.date}
                                    </Moment>
                                </p>
                                <Link
                                    to={pupilId ? `/pupils/${pupilId}/meetings/${meeting._id}` : `/meetings/${meeting._id}`}
                                    className="list__item">
                                    <p>{meeting.name}</p>
                                    <p className="list__desc">
                                        {(meeting.description || "").length > max
                                            ? (meeting.description || "").slice(0, max).concat('...')
                                            : (meeting.description || "")
                                        }
                                    </p>

                                    <div
                                        style={{
                                            paddingTop: '.5rem',
                                            borderTop: '1px solid #F4F7F9'
                                        }}
                                        className="list__desc">
                                        <p>{meeting.pupil?.surname} {meeting.pupil?.name}</p>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                {role === 'psych' && <Link
                    to={pupilId ? `/pupils/${pupilId}/meetings/create` : '/meetings/schools'}
                    className="main-btn main-btn_purple">
                    Назначить встречу
                </Link>}
            </div>
        </Layout>
    )
}