import React, { useState, useEffect } from 'react'
import {Link, useParams, Redirect, useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from '../redux/actions/setAppActions'
import { Alert } from '../components/Alert'
import axios from 'axios'
import moment from "moment";
import IMask from "imask";
import {FaCheck} from "react-icons/all";
import policy from "../static/docs/policy.pdf";


const initialState = {
    sex: '1',
    name: '',
    surname: '',
    patronymic: '',
    birthday: '',
    email: '',
    password: '',
    terms: true,
    isChild: true,
    resolution: true,
}

export const Register = ({ role, isChild }) => {
    const dispatch = useDispatch()
    const classId = useParams().classId
    const [group, setGroup] = useState(null)
    const [redirect, setRedirect] = useState(false)
    const [form, setForm] = useState(initialState)
    const { alert } = useSelector(state => state.app)
    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState(false);
    const [isBirthdayCorrect, setIsBirthdayCorrect] = React.useState(false);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);

    function firstCheckboxHandler(){
        setFirstCheckboxChecked(!firstCheckboxChecked);
    }
    function secondCheckboxHandler(){
        setSecondCheckboxChecked(!secondCheckboxChecked);
    }
    useEffect(() => {
        if (role === 'pupil' && classId) {
            (async () => {
                const { data } = await axios.get(`classes/${classId}`)
                setGroup(data)
            })()
        }
    }, [classId, role])

    useEffect(() => {
        setForm({
            ...form,
            isChild: isChild
        })
    }, isChild)
    const register = async () => {
        if(isPasswordCorrect && isBirthdayCorrect){
            let basicDate = form.birthday;
            try {
                let data = form

                let ISODate = moment(data.birthday, "DD.MM.YYYY", true).toISOString()
                if (ISODate) {
                    data.birthday = ISODate
                }
                await axios.post(`${role}/register`, {
                    ...data, classId, psychId: group?.psych?._id
                })
                setForm({ ...form, 'birthday': basicDate })
                dispatch(showAlert({ type: 'success', text: 'Вы успешно добавлены в систему!' }))
                setRedirect(true)
            } catch (e) {
                if (e.response.data.message) {
                    dispatch(showAlert({ type: 'error', text: e.response.data.message }))
                } else {
                    dispatch(showAlert({ type: 'error', text: e.message }))
                }
                setForm({ ...form, 'birthday': basicDate })
            }
        } else {
            if(!isBirthdayCorrect){
                dispatch(showAlert({ type: 'error', text: 'Указана неверная дата рождения!' }))
            } else if(!isPasswordCorrect){
                dispatch(showAlert({ type: 'error', text: 'Пароль должен быть больше 8-ми символов!' }))
            }
        }

    }
    useEffect(() => {
        if(form.password.length >= 8){
            if(!isPasswordCorrect){
                setIsPasswordCorrect(true);
            }
        } else {
            if(isPasswordCorrect){
                setIsPasswordCorrect(false);
            }
        }
    }, [form.password])

    useEffect(() => {
        let isDate = moment(form.birthday, "DD.MM.YYYY", true).isBetween(-1577934000000, moment.now())

        if(isDate){
            if(!isBirthdayCorrect){
                setIsBirthdayCorrect(true);
            }
        } else {
            if(isBirthdayCorrect){
                setIsBirthdayCorrect(false);
            }
        }
    }, [form.birthday])
    const changeHandler = event => {
        switch (event.target.name){
            case 'password':
                if(event.target.value.length <= 10){
                    setForm({ ...form, [event.target.name]: event.target.value })
                }
                break;
            case 'birthday':
                if(event.target.value.length <=10){
                    setForm({ ...form, [event.target.name]: event.target.value })
                }
                break;
            default:
                setForm({ ...form, [event.target.name]: event.target.value })
        }
    }
    useEffect(() => {
        const maskedBirthday = document.getElementById('birthday');
        const maskOptions = {
            mask: '00.00.0000'
        };
        IMask(maskedBirthday, maskOptions);
    }, [])

    if (redirect) {
        setTimeout( () => {
            window.location.href = '/'
        }, 2000)
    }


    return (
        <div className='main'>
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">
                        Регистрация ученика
                    </p>
                </header>

                <div className="auth-page__wrapper">
                    <div className="auth-page__header">
                        <h3>Регистрация</h3>
                        <Link
                            to={`/${role}/login/${classId || ''}`}
                            className="auth-page__header-btn">
                            Есть аккаунт?
                        </Link>
                    </div>
                    {group &&
                    <div className="auth-page__info">
                        <div className="auth-page__info-item">
                            <div>
                                <p>{group.psych.name} {group.psych.surname}</p>
                                <p className="auth-page__info-desc">Ваш психолог</p>
                            </div>
                        </div>
                        <div className="auth-page__info-item">
                            <p className="auth-page__info-title">Школа</p>
                            <p>{group.school.name}</p>
                        </div>
                        <div className="auth-page__info-item">
                            <p className="auth-page__info-title">Класс</p>
                            <p>{group.number}{group.letter}</p>
                        </div>
                    </div>
                    }
                    <div className="auth-page__input-group auth-page__input-group_row">
                        <div className="input-field">
                            <input
                                id="m"
                                type="radio"
                                name="sex" value='1'
                                checked={form.sex === '1'}
                                onChange={changeHandler}
                            />
                            <label htmlFor="m">Мужской</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="f"
                                type="radio"
                                name="sex" value='2'
                                checked={form.sex === '2'}
                                onChange={changeHandler}
                            />
                            <label htmlFor="f">Женский</label>
                        </div>
                    </div>

                    <div className="auth-page__input-group">
                        <div className="input-field">
                            <input
                                id="surname"
                                type="text"
                                name="surname"
                                placeholder="Фамилия"
                                onChange={changeHandler}
                                value={form.surname}
                            />
                            <label htmlFor="surname">Фамилия</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="name"
                                type="text"
                                name="name"
                                onChange={changeHandler}
                                placeholder="Имя"
                                value={form.name}
                            />
                            <label htmlFor="name">Имя</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="patronymic"
                                type="text"
                                name="patronymic"
                                onChange={changeHandler}
                                placeholder="Отчество"
                                value={form.patronymic}
                            />
                            <label htmlFor="patronymic">Отчество</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="birthday"
                                type="text"
                                name="birthday"
                                pattern="[0-9]{2}.[0-9]{2}.[0-9]{4}"
                                onChange={changeHandler}
                                value={form.birthday}
                                placeholder="Дата рождения"
                            />
                            <label htmlFor="birthday">Дата рождения</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="email"
                                type="text"
                                name="email"
                                placeholder="Логин"
                                onChange={changeHandler}
                                value={form.email}
                            />
                            <label htmlFor="email">Логин</label>
                        </div>
                        <div className={isPasswordCorrect ? "input-field" : "input-field input-field_error"}>
                            <input
                                style={{marginTop: 0}}
                                id="password"
                                type="password"
                                name="password"
                                placeholder="Придумайте пароль"
                                onChange={changeHandler}
                                value={form.password}
                            />
                            <label htmlFor="password">Придумайте пароль</label>
                        </div>
                        <p style={{textAlign: 'left', opacity: 0.5}}>Длина пароля должна быть больше 8-ми символов</p>

                    </div>

                    <div className="auth-page__input-group">
                        <div className="input-field">
                            <input
                                id="terms"
                                type="checkbox"
                                checked={form.terms}
                                onChange={() => { setForm({ ...form, 'terms': !form.terms }) }}
                            />
                            <label htmlFor="terms">Пользовательское соглашение</label>
                        </div>
                        {role === 'pupil' ?
                            <div className="input-field">
                                <input
                                    id="resolution"
                                    type="checkbox"
                                    checked={form.resolution}
                                    onChange={() => { setForm({ ...form, 'resolution': !form.resolution }) }}
                                />
                                <label htmlFor="resolution">Разрешение родителей</label>
                            </div> : null
                        }
                    </div>
                    <div className="landing-contacts__col">
                        <div className="animatedCheckbox">
                            <div style={firstCheckboxChecked ? {backgroundColor: '#2330BA'} : {backgroundColor: 'white'}} onClick={firstCheckboxHandler} className="checkbox">
                                <FaCheck style={{filter: 'brightness(0) invert(1)'}}/>
                            </div>
                            <p>Настоящим я подтверждаю, что даю согласие на <a href={policy} download="download">обработку моих персональных данных</a> для целей заключения и исполнения Пользовательского соглашения*</p>
                        </div>
                        <div className="animatedCheckbox">
                            <div style={secondCheckboxChecked ? {backgroundColor: '#2330BA'} : {backgroundColor: 'white'}} onClick={secondCheckboxHandler} className="checkbox">
                                <FaCheck style={{filter: 'brightness(0) invert(1)'}}/>
                            </div>
                            <p>Настоящим я подтверждаю, что даю согласие на <a href={policy} download="download">обработку моих персональных данных</a> для целей информирования о новых продуктах
                                и услугах, специальных предложениях и различных событиях, связанных
                                с деятельностью компании ООО «ЛССР»</p>
                        </div>
                    </div>
                    <p style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
                        Нажимая «Зарегистрироваться», вы подтверждаете, что прочитали
                        <a href={policy} download="download"> Политику обработки персональных данных</a> и согласны с
                        <Link to="#"> Условиями оказания услуг.</Link></p>
                    <button
                        style={(firstCheckboxChecked && secondCheckboxChecked) ? {} : {background: 'gray', boxShadow: 'none'}}
                        onClick={register}
                        className="main-btn auth-page__btn">
                        Зарегистрироваться
                    </button>
                </div>
            </div>
        </div>
    )
}
