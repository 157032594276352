import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FaPen } from 'react-icons/fa'
import { FiArchive } from "react-icons/fi"
import { BackButton } from '../components/BackButton'
import { Layout } from '../components/Layout'
import axios from 'axios'
import trashImg from '../static/img/trash.svg'

const styles = {
    button: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        top: '1rem',
        right: '1rem',
        width: '2rem',
        height: '2rem',
        background: '#f4f7f9',
        borderRadius: '7px',
    },
    h4: {
        color: '#122443',
        fontSize: '20px',
        letterSpacing: '0.4px',
        marginBottom: '1rem',
        fontWeight: 'normal'
    },
    list: {
        flexGrow: 'initial',
        marginBottom: '3rem'
    }
}

export const Pupils = () => {

    let touchstartX = 0;
    let currentTouch = 0;
    const history = useHistory()
    const classId = useParams().id
    const [pupils, setPupils] = useState([])

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/${classId}/pupils`)
                setPupils(data)
            })()
        }
    }, [classId])
    const archive = async e => {
        e.stopPropagation()
        const pupil = pupils.filter(p => p._id === e.currentTarget.id)[0]
        if (pupil && window.confirm('Подтвердите внесение изменений')) {
            const { data } = await axios.post(`pupil/${e.currentTarget.id}`, {
                isArchive: !pupil.isArchive,
                categories: pupil.categories
            })

            setPupils(pupils.map(pupil => pupil._id === data._id ? data : pupil))
        }
    }
    let isTouched = false;
    let isFixed = false;
    function handleTouchStart(event){
        if(event.touches){
            touchstartX = event.touches[0].screenX;
        } else {
            touchstartX = event.screenX;
        }
        isTouched = true;
    }
    function handleTouchEnd(event){
        isTouched = false;
        if(touchstartX - currentTouch < 100 && !isFixed){
            isFixed = false;
            event.currentTarget.style.marginLeft = 0 + 'px';
        }
    }
    function handleMove(event){
        if(event.touches){
            currentTouch = event.touches[0].screenX;
        } else {
            currentTouch = event.screenX;
        }
        if(currentTouch < touchstartX && isTouched && !isFixed){
            if(touchstartX - currentTouch < 100){
                event.currentTarget.style.marginLeft = ((touchstartX - currentTouch) * (-1)) + 'px';
            } else {
                isFixed = true;
            }
        }
    }
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">
                        {pupils[0]?.class.number}{pupils[0]?.class.letter}
                    </p>
                    {classId && <Link
                        to={`/classes/${classId}`}
                        className="icon-btn page__icon-btn page__icon-btn_right">
                        <FaPen />
                    </Link>}
                </header>

                <div className="school-page__wrapper">
                    <div className="list gesturedZone" style={styles.list}>
                        {pupils.filter(p => !p.isArchive).map(pupil =>
                            <div
                                key={pupil._id}
                                style={{
                                    position: 'relative'
                                }}
                                onClick={(e) => {
                                    // if(touchstartX === e.screenX){
                                    //     if(isFixed){
                                    //         e.currentTarget.style.marginLeft = 0 + 'px';
                                    //         isFixed = false
                                    //     } else {
                                            if (window.location.href.indexOf("/meetings/classes/") !== -1) {
                                                history.push(`/pupils/${pupil._id}/meetings/create`)
                                            } else {
                                                history.push(`/pupils/${pupil._id}`)
                                            }
                                    //     }
                                    // }
                                }}
                                // onTouchStart={handleTouchStart}
                                // onTouchEnd={handleTouchEnd}
                                // onMouseDown={handleTouchStart}
                                // onMouseMove={handleMove}
                                // onMouseUp={handleTouchEnd}
                                // onTouchMove={handleMove}
                                className="list__item">
                                <p>{pupil.name} {pupil.surname}</p>
                                <p className="list__desc">
                                    {pupil.psych?.name} {pupil.psych?.surname}
                                </p>
                                <button
                                    id={pupil._id}
                                    onClick={archive}
                                    style={styles.button}>
                                    <FiArchive style={{ fontSize: '16px' }} />
                                </button>
                                <div className="deleteStudent">
                                    <img alt="" src={trashImg}/>
                                </div>
                            </div>
                        )}
                    </div>
                    <h4 style={styles.h4}>Архив</h4>
                    <div className="list">
                        {pupils.filter(p => p.isArchive).map(pupil =>
                            <div
                                key={pupil._id}
                                style={{
                                    position: 'relative'
                                }}
                                onClick={() => {
                                    if (window.location.href.indexOf("/meetings/classes/") !== -1) {
                                        history.push(`/pupils/${pupil._id}/meetings/create`)
                                    } else {
                                        history.push(`/pupils/${pupil._id}`)
                                    }
                                }}
                                className="list__item">
                                <p>{pupil.name} {pupil.surname}</p>
                                <p className="list__desc">
                                    {pupil.psych?.name} {pupil.psych?.surname}
                                </p>
                                <button
                                    id={pupil._id}
                                    onClick={archive}
                                    style={styles.button}>
                                    <FiArchive style={{ fontSize: '16px' }} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}