import React from 'react'
import '../static/scss/sureAlert.scss'
import close from '../static/img/ic-close.svg';
export const SureAlert = (props) => {


        return (
            <div className="sureAlert">
                <div className="sureAlert__inner">
                    <h3>Предупреждение</h3>
                    {props.type === 'createTest' ?
                        <p>Вы точно уверены, что хотите назначить данный тест?
                            <br/>
                            <br/>
                            Отменить это действие можно будет обратившись к нам по почте school@testu.online</p>
                    : ''}
                    {props.type === 'createMeeting' ?
                    <p>Вы точно уверены, что хотите назначить данную встречу?
                        <br/>
                        <br/>
                        Отменить это действие можно будет обратившись к нам по почте school@testu.online</p>
                        : ''}
                    {props.type === 'createNote' ?
                    <p>Вы точно уверены, что хотите добавить данную заметку?
                        <br/>
                        <br/>
                        Отменить это действие можно будет обратившись к нам по почте school@testu.online</p>
                        : ''}
                    {props.type === 'createAdvice' ?
                    <p>Вы точно уверены, что хотите добавить данный совет?
                        <br/>
                        <br/>
                        Отменить это действие можно будет обратившись к нам по почте school@testu.online</p>
                        : ''}
                    <button onClick={props.handleSubmit} className="send">Отправить</button>
                    <button onClick={props.handleClose} className="close">
                        <img alt="" src={close}/>
                    </button>
                </div>
            </div>
        )

}