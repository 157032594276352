import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'
import { Layout } from "../../components/Layout"
import { useDispatch, useSelector } from "react-redux"
import { BackButton } from "../../components/BackButton"
import moment from 'moment'


export const StatisticFilters = () => {
    const dispatch = useDispatch()
    const classId = useParams().classId
    const pupilId = useParams().pupilId
    const [conditions, setConditions] = useState([])
    const [filtered, setFiltered] = useState([])
    const [dates, setDates] = useState([])
    const [search, setSearch] = useState('')
    const [categories, setCategories] = useState([])
    const { filters } = useSelector(state => state.statistic)
    const [loadedScales, setLoadedScales] = useState([]);
    const [dropdownScales, setDropdownScales] = useState(false);
    const [chosenScale, setChosenScale] = useState({
        name: 'Все шкалы'
    });
    const [dropdownLevels, setDropdownLevels] = useState(false);
    const [chosenLevels, setChosenLevels] = useState(0);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get('categories')
            setCategories(data.categories)
        })()
    }, [])
    useEffect(() => {
        (async () => {
            let array = [{
                name: 'Все шкалы'
            }];
            const { data } = await axios.get('conditions/scales')

            if(filters.condition){
                let newScales = [];
                console.log(data)
                for(let scale of data){
                    console.log(filters.condition + '.....' + scale.conditionId)
                    if(filters.condition === scale.conditionId){
                        newScales.push(scale)
                    }
                }
                setLoadedScales([...array, ...newScales])

            } else {
                setLoadedScales([...array, ...data])

            }



        })()
    }, [filters.condition])
    console.log(loadedScales)
    useEffect(() => {
        if(filters.scale!=='Все шкалы' && filters.scale!==''){
            loadedScales.map((scale) => {
                if(scale.name === filters.scale){
                    setChosenScale(scale);
                }
            })
        }
    }, [filters.scale, loadedScales])
    useEffect(() => {
        const arr = []

        if (classId) {
            arr.push(`classId=${classId}`)
        }

        if (pupilId) {
            arr.push(`pupilId=${pupilId}`)
        }

        if (filters.date) {
            arr.push(`date=${filters.date}`)
        }

        (async () => {
            const { data } = await axios.get(`tests/conditions?${arr.join('&')}`)
            setConditions(data)
        })()
    }, [filters, classId, pupilId])

    useEffect(() => {
        const arr = []

        if (classId) {
            arr.push(`classId=${classId}`)
        }

        if (pupilId) {
            arr.push(`pupilId=${pupilId}`)
        }

        if (filters.condition) {
            arr.push(`condition=${filters.condition}`)
        }

        (async () => {
            const { data } = await axios.get(`tests/dates?${arr.join('&')}`)
            setDates(data)
        })()

    }, [filters, classId, pupilId])

    useEffect(() => {
        const data = conditions.filter(t => t.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
        setFiltered(data)
    }, [conditions, search])

    const changeHandler = e => {
        if (e.target.name === 'years') {
            const pattern = /^[,0-9 ]*$/i
            const inputValid = pattern.test(e.target.value.replace(/ /g, ''))
            if (!inputValid) return
        }

        dispatch({
            type: 'STATISTIC/SET_FILTERS',
            payload: { ...filters, [e.target.name]: e.target.value }
        })
    }

    const categoryHandler = evt => {
        const category = filters.category === evt.target.id ? '' : evt.target.id

        dispatch({
            type: 'STATISTIC/SET_FILTERS',
            payload: { ...filters, category }
        })
    }

    function setFilterScale(scale){
        if(scale !== 'Все шкалы'){
            dispatch({
                type: 'STATISTIC/SET_FILTERS',
                payload: { ...filters, scale: scale }
            })
        } else {
            dispatch({
                type: 'STATISTIC/SET_FILTERS',
                payload: { ...filters, scale: '' }
            })
        }

    }
    function setFilterLevel(level){
        dispatch({
            type: 'STATISTIC/SET_FILTERS',
            payload: { ...filters, level: level }
        })
    }
    const conditionHandler = e => {
        if (e.currentTarget.id === filters.condition) {
            let newPayload = filters;
            newPayload.condition = '';
            newPayload.scale = '';
            newPayload.level = 0;
            return dispatch({
                type: 'STATISTIC/SET_FILTERS',
                payload: newPayload
            })
        }
        let newPayload = filters;
        newPayload.condition = e.currentTarget.id;
        newPayload.scale = '';
        newPayload.level = 0;
        dispatch({
            type: 'STATISTIC/SET_FILTERS',
            payload: newPayload
        })
        setDropdownScales(false)
        setDropdownLevels(false)
    }
    console.log(loadedScales)
    return (
        <Layout>
            <div style={{paddingBottom:120}} className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Фильтры</p>
                </header>
                <div className="page__content">
                    {!pupilId && <>

                        <div className="auth-page__input-group auth-page__input-group_row">
                            <div className="input-field">
                                <input
                                    id="n"
                                    value=''
                                    name="sex"
                                    type="radio"
                                    checked={filters.sex === ''}
                                    onChange={changeHandler}
                                />
                                <label htmlFor="n">Все</label>
                            </div>
                            <div className="input-field">
                                <input
                                    id="m"
                                    name="sex"
                                    value='1'
                                    type="radio"
                                    checked={filters.sex === '1'}
                                    onChange={changeHandler}
                                />
                                <label htmlFor="m">Мужской</label>
                            </div>
                            <div className="input-field">
                                <input
                                    id="f"
                                    type="radio"
                                    name="sex"
                                    value='2'
                                    checked={filters.sex === '2'}
                                    onChange={changeHandler}
                                />
                                <label htmlFor="f">Женский</label>
                            </div>
                        </div>
                        <div className="auth-page__input-group">
                            <div className="input-field">
                                <input
                                    id="surname"
                                    type="text"
                                    name="years"
                                    placeholder="Укажите возраст учеников через запятую"
                                    onChange={changeHandler}
                                    value={filters.years}
                                />
                                <label htmlFor="years">Возраст</label>
                            </div>
                        </div>

                        <div>
                            <div className="list">
                                {categories.map((category =>
                                    <div
                                        key={category._id}
                                        className="list__checkbox">
                                        <input
                                            type="checkbox"
                                            id={category._id}
                                            value={category._id}
                                            onChange={categoryHandler}
                                            checked={filters.category === category._id}
                                        />
                                        <label htmlFor={category._id}>
                                            <div>
                                                <p>{category.title}</p>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                    <div>
                        <h3>Тест</h3>
                        <div className="list">
                            {filtered.map(condition =>
                                <div
                                    id={condition._id}
                                    key={condition._id}
                                    onClick={conditionHandler}
                                    className="list__item"
                                    style={filters.condition === condition._id ? {
                                        color: "#fff",
                                        background: "linear-gradient(to left, #00c6ff, #0072ff)",
                                    } : {}}>
                                    <p>{condition.name}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    {filters.condition ?
                        <div>
                            <h3 style={{textAlign: "left"}}>Шкала</h3>
                            <div className="auth-page__input-group">
                                <div onClick={() => {setDropdownScales(!dropdownScales); setDropdownLevels(false)}} className="input-field">
                                    <input id="scale" type="text"
                                           style={{pointerEvents: 'none'}}
                                           name="scale"

                                           value={filters.scale ? filters.scale : 'Все шкалы' } />
                                    <label htmlFor="scale">Шкала</label>
                                    {dropdownScales ?
                                        <div className="input-field__dropdown">
                                            {loadedScales.map((scale) => {
                                                return(
                                                    <p onClick={() => {setChosenScale(scale); setFilterScale(scale.name)}}>{scale.name}</p>
                                                )
                                            })}
                                        </div>
                                        : ''}

                                </div>

                            </div>
                        </div>
                        : ''}

                    {filters.scale !== 'Все шкалы' && filters.scale !== '' && !pupilId  ?
                        <div>
                            <h3 style={{textAlign: "left"}}>Уровень</h3>
                            <div className="auth-page__input-group">
                                <div onClick={() => {setDropdownLevels(!dropdownLevels); setDropdownScales(false)}} className="input-field">
                                    <input id="level" type="text" name="level"
                                           style={{pointerEvents: 'none'}}
                                            value={filters.level === 0 ? 'Выберите уровень' : 'Уровень ' + filters.level} />
                                    <label htmlFor="level">Уровень</label>
                                    {dropdownLevels ?
                                        <div className="input-field__dropdown">
                                            {
                                                Array.from(Array(chosenScale.levels)).map((e, i) => <p onClick={() => {setChosenLevels(i + 1); setFilterLevel(i + 1)}} key={i}>Уровень {i + 1}</p>)
                                            }
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    : ''}
                </div>
            </div>
        </Layout >
    )
}