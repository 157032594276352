import {Link, useLocation} from 'react-router-dom'
import React, {useEffect, useState} from "react";
import { useSelector } from 'react-redux'


export const Info = ({ handler, name, desc, instruction, id, conditionId, solution, instructionsHandler, author, desTeacher, message, scales, fromTests, isVisible }) => {
    const { role } = useSelector(state => state.auth)
    const { conditions } = useSelector(state => state.test)
    const [step, setStep] = React.useState(0);
    const [showScaleDescription, setShowScaleDescription] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    let location = useLocation();
    useEffect(() => {
        if (location.pathname.includes('instruction') && !fromTests) {
            setStep(1)
        }
    }, [])
    if(showDescription){
        return(
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {setShowDescription(false)}}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                        <p className="page__title">Описание для специалистов</p>
                        <p style={{marginBottom: '20px',marginTop: '40px', opacity: '0.5'}} dangerouslySetInnerHTML={{ __html: desTeacher }} />
                    </header>
                    <h4 style={{marginBottom: '10px', fontWeight: '500', fontSize: '18px'}}></h4>
                </div>
            )

    }
    if(showScaleDescription){
        return(
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {setShowScaleDescription(false)}}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                        <p className="page__title">Шкалы</p>
                    </header>
                    {scales ? scales.map((scale, orderId) => {
                        return(
                            <div style={{ flexGrow: 1 , marginBottom: '30px'}}>
                                <h4 style={{fontSize: '18px', fontWeight: 500, marginBottom: '10px'}}>{(orderId + 1) + '. ' + scale.name}</h4>
                                <p style={{fontSize: '14px', opacity: '0.5', fontWeight: 400}} dangerouslySetInnerHTML={{ __html: scale.description }} />
                            </div>
                        )
                    }): ''
                    }
                </div>
        )
    }
    if(step === 0){
        return (
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={handler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    <p className="page__title">Информация о тесте</p>
                </header>
                <div style={{ flexGrow: 1 }}>
                    {role === 'psych' || isVisible ? <h3 style={{marginBottom: '10px'}}>{name}</h3> : <h3 style={{marginBottom: '10px'}}>Тест</h3>}
                    {role === 'psych' ?
                    <>
                        <h4 style={{marginBottom: '30px', fontWeight: '500', opacity: '0.5'}}>Авторы: {author}</h4>


                    </> : ''}
                    <h4 style={{marginBottom: '10px', fontWeight: '500', fontSize: '18px'}}>Описание для ученика</h4>
                    <p style={{marginBottom: '20px', opacity: '0.5'}} dangerouslySetInnerHTML={{ __html: desc }} />
                    {role !== 'pupil' ?
                        <>
                            <h4 style={{marginBottom: '10px', fontWeight: '500', fontSize: '18px'}}>Инструкция для
                                ученика</h4>
                            <p style={{marginBottom: '50px', opacity: '0.5'}} dangerouslySetInnerHTML={{__html: message}}/>
                        </>
                        :''
                    }

                </div>
                {role !== 'pupil' ? null :
                    <>
                        <a onClick={() => {setStep(1)}} className="main-btn">
                            {role === 'pupil' ? 'Читать инструкцию' : 'Посмотреть вопросы'}
                        </a>
                    </>

                }
                {role === 'pupil' ? null :
                    <>
                        <a onClick={() => {setShowDescription(true)}} style={{marginBottom: '20px', background: 'linear-gradient(270deg, #EB3349 0%, #F45C43 100%)', boxShadow: '0px 2px 10px -3px #EF4746', cursor: 'pointer'}} className="main-btn">
                            Информация о методике
                        </a>
                        <a onClick={() => {setShowScaleDescription(true)}} style={{marginBottom: '20px', background: 'linear-gradient(90deg, #ff8008 0%, #ffc837 100%)', boxShadow: '0px 2px 10px -3px #ffa520', cursor: 'pointer'}} className="main-btn">
                            Посмотреть шкалы
                        </a>
                        <Link to={`/tests/${id}/${conditionId}`} className="main-btn">
                            Посмотреть вопросы
                        </Link>
                    </>

                }
            </div>
        )
    }
    return (
        <div className="page">
            <header className="page__header">
                <button
                    onClick={() => {setStep(0)}}
                    className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                <p className="page__title">Инструкция</p>
            </header>
            <div style={{ flexGrow: 1 }}>
                {role === 'psych' || isVisible ? <h3>{name}</h3> : <h3>Тест</h3>}
                <p dangerouslySetInnerHTML={{ __html: instruction }} />


            </div>
            {solution && role === 'pupil' ? null :
                <Link to={`/tests/${id}/${conditionId}`} className="main-btn">
                    {role === 'pupil' ? 'Пройти тест' : 'Посмотреть вопросы'}
                </Link>
            }
        </div>
    )

}