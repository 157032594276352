import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { BackButton } from '../../components/BackButton'
import { Layout } from '../../components/Layout'


const initialState = {
    number: '',
    letter: '',
    max: ''
}

export const ClassForm = () => {
    const history = useHistory()
    const classId = useParams().id
    const schoolId = useParams().schoolId
    const [data, setData] = useState(null)
    const [form, setForm] = useState(initialState)

    useEffect(() => {
        if (classId) {
            (async () => {
                const { data } = await axios.get(`classes/${classId}`)
                setData(data)
                setForm({
                    ...initialState,
                    number: data.number,
                    letter: data.letter,
                    max: data.max
                })
            })()
        }
    }, [classId])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const submit = async e => {
        e.preventDefault()
        if (data) {
            await axios.post(`classes/${data._id}`, {
                ...form, schoolId
            })
        } else {
            let data = form
            data.schoolId = schoolId
            await axios.post('classes/create', form)
        }

        history.goBack()
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Классы</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>{data ? 'Изменить класс' : 'Добавить класс'}</h3>
                    <div className="input-field">
                        <input
                            id="number"
                            type="number"
                            name="number"
                            placeholder="Номер"
                            onInput={changeHandler}
                            value={form.number}
                        />
                        <label htmlFor="number">Номер</label>
                    </div>
                    <div className="input-field">
                        <input
                            id="letter"
                            type="text"
                            name="letter"
                            placeholder="Буква"
                            onChange={changeHandler
                            } value={form.letter}
                        />
                        <label htmlFor="letter">Буква</label>
                    </div>
                    <div className="input-field">
                        <input
                            id="max"
                            type="number"
                            name="max"
                            placeholder="Максимальное количество"
                            onChange={changeHandler}
                            value={form.max}
                        />
                        <label htmlFor="letter">Максимальное количество</label>
                    </div>
                    <p style={{opacity: 0.3}}>Поле “Максимальное количество” запрашивает у вас число пользователей для того, чтобы в системе не смог зарегистрироваться дополнительно кто-то внешний (например если кто-то разместит активный QR код созданного вами класса в Интернете)</p>

                </div>
                <button
                    onClick={submit}
                    disabled={false}
                    className="main-btn">
                    {data ? 'Изменить класс' : 'Добавить класс'}
                </button>
            </div>
        </Layout>
    )
}