import React, { useState, useEffect } from "react"
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { BackButton } from "../../components/BackButton"
import { Range } from "../../components/Range"
import { Layout } from "../../components/Layout"
import { useSelector } from "react-redux"
import fetch from "../../redux/fetch";
import search from "../../static/img/ic-search.svg";


export const StatisticPupils = () => {
    const classId = useParams().id

    const { filters } = useSelector(state => state.statistic)
    const [filteredPupils, setFilteredPupils] = useState([]);
    const [searchField, setSearchField] = useState('');
    const [afterSearch, setAfterSearch] = useState([]);
    function searchHandler(e){
        setSearchField(e.target.value);
    }

    useEffect(async () => {
        if (classId) {
            const arr = []

            let pupils =  await fetch(`/api/classes/${classId}/pupils`, { method: 'GET' })
            console.log(pupils)

            if(pupils){
                console.log(filters)
                if (filters.sex) {
                    let filteredPupils = [];
                    for(let i = 0; i < pupils.length; i++){
                        if(pupils[i].sex === filters.sex){
                            filteredPupils.push(pupils[i]);
                        }
                    }
                    pupils = filteredPupils;
                }

                if (filters.years) {
                    let targetAges = filters.years.replace(/\s/g, '').split(',');
                    let filteredPupils = [];
                    for(let i = 0; i < pupils.length; i++){
                        let currentYears = pupils[i].birthday;
                        let date = new Date(currentYears);
                        let ageDateNumber = Date.now() - date;
                        let ageDate = new Date(ageDateNumber)
                        let year = ageDate.getUTCFullYear();
                        let age = Math.abs(1970 - year);
                        for(let j = 0; j < targetAges.length; j++){
                            if(targetAges[j].toString() === age.toString()){
                                filteredPupils.push(pupils[i]);
                            }
                        }
                    }
                    pupils = filteredPupils;
                }

                if (filters.category) {

                    let filteredPupils = [];
                    for(let i = 0; i < pupils.length; i++){
                        for(let j = 0; j < pupils[i].categories.length; j++){
                            if(pupils[i].categories[j] === filters.category){
                                filteredPupils.push(pupils[i]);
                            }
                        }
                    }
                    pupils = filteredPupils;
                }

                if(filters.scale){
                    let filteredPupils = [];
                    for(let pupil of pupils){

                        if(pupil.levels){
                            for(let level of pupil.levels){
                              if(level.name === filters.scale){
                                  filteredPupils.push(pupil);
                              }
                            }
                        }

                    }
                    pupils = filteredPupils;

                }
                if(filters.level){
                    let filteredPupils = [];
                    console.log(pupils)
                    for(let pupil of pupils){
                        if(pupil.levels){
                            for(let level of pupil.levels){
                                if(level.level === filters.level && level.name === filters.scale){
                                    filteredPupils.push(pupil);
                                }
                            }
                        }
                    }
                    pupils = filteredPupils;
                }

            }

            setFilteredPupils(pupils)

        }
    }, [classId, filters])

    useEffect(() => {
        if(searchField.length > 0){
            let newPupils = [];
            for(let pupil of filteredPupils){
                let name = pupil.name;
                let slicedName = name.slice(0, searchField.length)
                let lastName = pupil.surname;
                let slicedLast = lastName.slice(0, searchField.length)
                if(slicedName.toLowerCase() === searchField.toLowerCase() || slicedLast.toLowerCase() === searchField.toLowerCase()){
                    newPupils.push(pupil)
                }
            }
            setAfterSearch(newPupils)

        } else {
            setAfterSearch(filteredPupils)
        }
    }, [searchField, filteredPupils])
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">
                        Статистика
                    </p>

                    <Link
                        to={`/statistic/classes/${classId}/filters`}
                        className={!!Object.values(filters).filter(val => !!val.length).length ? `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_active` : `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter`}>
                        {!!Object.values(filters).filter(val => !!val.length).length && <span>
                            {Object.values(filters).filter(val => !!val.length).length}
                            <div className="dot"></div>
                        </span>}
                    </Link>
                </header>

                <div>
                    <div className="school-page__search">
                        <img src={search}/>
                        <input onChange={searchHandler} value={searchField} placeholder="Поиск"/>
                    </div>
                    {afterSearch.map(pupil => (
                        <Link
                            key={pupil._id}
                            to={`/statistic/pupils/${pupil._id}`}
                            className="statistic-condition">
                            <p className="statistic-condition__title">
                                {pupil.name} {pupil.surname}
                            </p>

                            <Range
                                total={pupil.tests.length}
                                norm={pupil.doneTestQuantity}
                                // notNorm={pupil.negatively}
                                notNorm={pupil.tests.length - pupil.doneTestQuantity}
                            />


                            <div className="statistic-condition__footer">
                                <span className="statistic-condition__indicator green">
                                    {pupil.doneTestQuantity}
                                </span>
                                <span className="statistic-condition__indicator blue">
                                     {pupil.tests.length - pupil.doneTestQuantity}
                                </span>
                                <p className="statistic-condition__members">
                                    {pupil.tests.length} тестов
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </Layout>
    )
}