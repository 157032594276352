import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { BackButton } from '../components/BackButton'
import { Layout } from '../components/Layout'
import { Loader } from '../components/Loader'
import { createAdvice, getAdvices, readAdvices } from '../redux/actions/setAdviceActions'
import {SureAlert} from "../components/SureAlert";


const CreateAdvice = ({ createHandler, closeHandler }) => {
    const [form, setForm] = useState({ title: '', text: '' })
    const [isSureAlert, setIsSureAlert] = useState(false)
    function onSendClick(event) {
        setIsSureAlert(true);
    }
    function onCloseClick(event) {
        setIsSureAlert(false);
    }

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={closeHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    <p className="page__title">Рекомендации</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>Написать рекомендацию</h3>
                    <div className="input-field input-field_green">
                        <input
                            id="title"
                            type="text"
                            name="title"
                            value={form.title}
                            placeholder="Заголовок рекомендации"
                            onChange={changeHandler}
                        />
                        <label htmlFor="number">Заголовок</label>
                    </div>
                    <div className="input-field input-field_green">
                        <input
                            id="text"
                            type="text"
                            name="text"
                            value={form.text}
                            onChange={changeHandler}
                            placeholder="Подробности рекомендации"
                        />
                        <label htmlFor="letter">Подробности</label>
                    </div>
                </div>

                <button
                    onClick={onSendClick}
                    disabled={!form.title || !form.text}
                    className="main-btn main-btn_green">
                    Отправить
                </button>
            </div>
            {isSureAlert ? <SureAlert type='createAdvice' handleClose={onCloseClick} handleSubmit={() => {createHandler(form); onCloseClick()}}/> : ''}
        </Layout>
    )
}


export const AdvicesPage = () => {
    const dispatch = useDispatch()
    const { search } = useLocation()
    const [pupilId, setPupilId] = useState(null)
    const [advice, setAdvice] = useState(null)
    const [isCreate, setIsCreate] = useState(false)
    const { advices } = useSelector(state => state.advice)
    const { loading } = useSelector(state => state.app)
    const { role } = useSelector(state => state.auth)


    useEffect(() => {
        const query = new URLSearchParams(search)
        const pupilId = query.get('pupilId')
        dispatch(getAdvices(pupilId))
        setPupilId(pupilId)

        if (role === 'pupil') {
            dispatch(readAdvices())
        }
    }, [dispatch, search, role])

    const createHandler = async (form) => {
        dispatch(createAdvice({ ...form, pupilId }))
    }

    if (loading) return <Loader />

    if (isCreate) {
        return <CreateAdvice
            closeHandler={() => setIsCreate(!isCreate)}
            createHandler={createHandler} />
    }

    if (advice) return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={() => setAdvice(null)}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    <p className="page__title">
                        <Moment locale="ru" format="DD MMM, YYYY">
                            {advice.createdAt}
                        </Moment>
                    </p>
                </header>
                <div className="page__content">
                    <h3>{advice.title}</h3>
                    <p>{advice.text}</p>
                </div>
            </div>
        </Layout>
    )

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    {role === 'psych' && <button
                        onClick={() => setIsCreate(!isCreate)}
                        className="icon-btn  page__icon-btn page__icon-btn_right icon-btn_add" />}
                    <p className="page__title">Рекомендации</p>
                </header>
                <div className="school-page__wrapper">
                    <div className="list">
                        {advices.map(advice => {
                            const max = 70
                            return (
                                <div key={advice._id} className="list__item-wrap">
                                    <p className="list__item-date">
                                        <Moment locale="ru" format="DD MMM, YYYY">
                                            {advice.createdAt}
                                        </Moment>
                                    </p>
                                    <div
                                        className="list__item"
                                        onClick={() => setAdvice(advice)}>
                                        <p>{advice.title}</p>
                                        <p className="list__desc">
                                            {advice.text.length > max
                                                ? advice.text.slice(0, max).concat('...')
                                                : advice.text}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {role === 'psych' && <button
                    onClick={() => setIsCreate(!isCreate)}
                    className="main-btn main-btn_green">Написать рекомендацию</button>}
            </div>
        </Layout>
    )
}