import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from '../redux/actions/setAppActions'
import { Alert } from '../components/Alert'


export const LosePassPage = ({ role }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({ email: '' })
    const { alert } = useSelector(state => state.app)

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const sendPassHandler = async () => {
        setLoading(true)
        try {
            const res = await fetch(`/api/${role}/lose/password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(form)
            })
            const data = await res.json()

            if (!res.ok) {
                throw new Error(data.message || 'Что-то пошло не так')
            }

            dispatch(showAlert({ type: 'success', text: data.message }))
            setLoading(false)

        } catch (error) {
            dispatch(showAlert({ type: 'error', text: error.message }))
            setLoading(false)
        }
    }

    return (
        <div className='main'>
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">Восстановление пароля</p>
                </header>
                <div className="auth-page__wrapper">
                    <div className="auth-page__header">
                        <h3>Забыли пароль?</h3>
                    </div>
                    <div className="auth-page__input-group">
                        <div className="input-field">
                            <input
                                id="email"
                                type="text"
                                name="email"
                                value={form.email}
                                onChange={changeHandler}
                                placeholder="Укажите вашу почту" />
                            <label htmlFor="email">Эл. почта</label>
                        </div>
                        {role === 'psych' ? <Link to={'/settings/security'}>Уже есть пароль?</Link> : <Link to={`/${role}/login`}>Уже есть пароль?</Link>}

                    </div>
                    <button
                        disabled={loading}
                        onClick={sendPassHandler}
                        className="main-btn auth-page__btn">
                        Отправить пароль на почту
                    </button>
                </div>
            </div>
        </div>
    )
}