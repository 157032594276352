import React, { useEffect, useState, useRef } from 'react'
import {Link, useLocation} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import IMask from 'imask';

import { useSheet } from '../hooks/sheet.hook'
import logo from '../static/img/logo.svg'
import phone from '../static/img/phone.png'
import psych1 from '../static/img/psych1.svg'
import psych2 from '../static/img/psych2.svg'
import attention from '../static/img/attention.svg'
import blocks from '../static/img/blocks.svg'
import close from '../static/img/close.svg'
import email from '../static/img/ic-email.svg'
import tel from '../static/img/ic-tel.svg'
import loc from '../static/img/ic-loc.svg'
import whiteLogo from '../static/img/whiteLogo.svg'
import diagram from '../static/img/diagram.svg'
import feather from '../static/img/feather.svg'
import check from '../static/img/check.svg'
import QR from '../static/img/QR.svg'
import sk from '../static/img/sk.png'
import books from '../static/img/books.png'
import bars from '../static/img/bars.svg'
import pc from '../static/img/pc.png'
import share from '../static/img/share.svg'
import clock from '../static/img/ic-clock.svg'
import radar from '../static/img/ic-radar.svg'
import pie from '../static/img/ic-pie.svg'
import forPupil from '../static/img/forPupilImg.png'
import forSchool from '../static/img/forSchool.png'
import forPsych from '../static/img/forPsych.png'
import FreemiumBanner from "./FreemiumBanner";
import planet from '../static/img/ic-planet.svg'
import exportIcon from '../static/img/ic-export.svg'
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {FaCheck} from "react-icons/all";
import '../static/scss/landing.scss'
import policy from '../static/docs/policy.pdf'
import userInstr from '../static/docs/userInstr.docx'

export const LandingPage = () => {



    const { appendSpreadsheet } = useSheet()
    const teaserComponent = useRef(null)
    const [isLogged, setIsLogged] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [forWhatBlock, setForWhatBlock] = React.useState(1);
    const [isMenuOpened, setIsMenuOpened] = React.useState(false);
    const [isMenuLoginOpened, setIsMenuLoginOpened] = React.useState(false);

    let dispatch = useDispatch();
    let location = useLocation();

    (async () => {
        try {
            const { data } = await axios.get('auth/user')
            dispatch({ type: 'AUTH/SET_USER', payload: data })
            setIsLogged(true);
        } catch (e) {
            setIsLogged(false);
        }
    })()


    function menuOpenHandler(){
        setIsMenuOpened(true);
    }
    function menuCloseHandler(){
        setIsMenuOpened(false);
        setIsMenuLoginOpened(false);
    }
    function menuLoginOpenHandler(){
        setIsMenuLoginOpened(true);
    }
    function menuLoginCloseHandler(){
        setIsMenuLoginOpened(false);
    }
    function showPopupHandler(){
        setShowPopup(!showPopup);
    }
    const [step, setStep] = useState(1)

    useEffect(() => {
        let element = document.getElementById('tel');

        let maskOptions = {
            mask: '+{7}(000)000-00-00'
        };
        if(element){

            IMask(element, maskOptions);
        }
    }, [step])
    const [service, setService] = useState(1)
    const [form, setForm] = useState({
        name: '',
        surname: '',
        secondName: '',
        tel: '',
        email: '',
        institutionType: '',
        institution: '',
        education: '',

    })

    function firstCheckboxHandler(){
        setFirstCheckboxChecked(!firstCheckboxChecked);
    }
    function secondCheckboxHandler(){
        setSecondCheckboxChecked(!secondCheckboxChecked);
    }

    useEffect(() => {
        if (window.innerWidth > 640) {
            setTimeout(() => {
                service === 3 ? setService(1) : setService(service + 1)
            }, 4000)
        }
    }, [service])

    const clickHandler = () => {
        if (window.innerWidth <= 640) {
            service === 3 ? setService(1) : setService(service + 1)
        }
    }

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const forwardHandler = () => {
        if (step === 1 && form.name && form.surname) {
            setStep(step + 1)
        } else if (step === 2 && form.tel && form.email) {
            setStep(step + 1)
        } else if (step === 3 && form.institutionType && form.institution) {
            setStep(step + 1)
        } else if (step === 4 && form.education) {

            appendSpreadsheet(form)
            setStep(1)
            setForm({
                name: '',
                surname: '',
                tel: '',
                email: '',
                institutionType: '',
                institution: '',
                education: '',
            })
            alert('Заявка успешно отправлена')
        } else {
            alert('Заполните все поля')
        }
    }

    return (
        <div className="landing">
            <header className="landing-header">
                <div className="landing-header__logo-wrapper">
                    <Link className="landing-header__logo" to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                {/*<nav className="landing-header__nav">*/}
                {/*    <HashLink className="landing-header__link" to="#landing-main">Главная</HashLink>*/}
                {/*    <HashLink className="landing-header__link" to="#landing-about">Наше решение</HashLink>*/}
                {/*    <HashLink className="landing-header__link" to="#forWhatAnchor">Для кого?</HashLink>*/}
                {/*    <HashLink className="landing-header__link" to="#quizAnchor">Наша методология</HashLink>*/}
                {/*    <a className="landing-header__link" href="#landing-goal">О компании</a>*/}
                {/*</nav>*/}
                <div className="landing-header__auth">
                    {isLogged ?
                        <>
                            {/*<a className="landing-header__link" href="https://constructor.testu.online">Конструктор</a>*/}
                            <Link className="landing-header__link" to="/dashboard">Профиль</Link>
                        </>
                        :
                        <>
                            {/*<button onClick={() => window.location.href='https://constructor.testu.online/'} className="landing-header__link" >Конструктор</button>*/}
                            <div className="landing-header__linkButton landing-header__dropdown" >
                                Войти
                                <div className="landing-header__linkContent-holder">
                                    <div className="landing-header__linkContent">
                                        <Link className="landing-header__link" to="/psych/login">Для психолога</Link>
                                        <Link className="landing-header__link noMargin" to="/pupil/login">Для ученика</Link>
                                    </div>
                                </div>
                            </div>

                        </>
                    }

                </div>
            </header>

            <section className="landing-main" id="landing-main">
                <h1 className="landing-main__title">Платформа автоматизации школьной психодиагностики</h1>
                <p className="landing-main__text">Мы создаем комфортную <span>среду<br className="mob-br"/> для коммуникации школьника и<br className="mob-br"/> психолога.</span> <br/>
                    Выбирайте <br className="mob-br"/><span>валидированные методики</span>, <br className="mob-br"/>проводите <span>психодиагностику</span>,<br className="mob-br"/><br/>получайте мгновенный <br className="mob-br"/><span>результат и аналитику</span> на <br className="mob-br"/>одной платформе.</p>
                <div className="landing-main__btns">
                    <Link to="/psych/login" className="landing-main__btn">Для психолога</Link>
                    <Link to="/pupil/login" className="landing-main__btn landing-main__btn_blue">Для ученика</Link>
                </div>
            </section>



            <section className="landing-contacts" id="landing-contacts">
                <h2 className="landing-contacts__title">Сделайте несколько шагов, чтобы познакомиться с нашим решением.</h2>
                <div className="landing-contacts__content">
                    <div className="landing-contacts__contacts">
                        <Link className="landing-contacts__contact landing-contacts__contact_email"
                              to="mailto:help@testu.online">
                            <div>
                                <p>help@testu.online</p>
                                <label>Техническая поддержка</label>
                            </div>
                        </Link>
                        <Link className="landing-contacts__contact landing-contacts__contact_email"
                              to="mailto:school@testu.onlin">
                            <div>
                                <p>school@testu.online</p>
                                <label>Для идей и предложений по развитию</label>
                            </div>
                        </Link>
                        <Link className="landing-contacts__contact landing-contacts__contact_email"
                              to="mailto:ceo@testu.online">
                            <div>
                                <p>ceo@testu.online</p>
                                <label>Обсуждение партнерства и интеграци</label>
                            </div>
                        </Link>
                        {/*<Link className="landing-contacts__contact landing-contacts__contact_tel"*/}
                        {/*      to="tel:+79663006996">+7 966 300 69 96</Link>*/}
                        <Link className="landing-contacts__contact landing-contacts__contact_address"
                              to="tel:+79663006996">Берсеневская набережная, 6 <br/>строение 3</Link>
                        <p className="landing-contacts__label">
                            Компания ООО “Лаборатория систем саморазвития” <br/>является резидентом Инновационного центра Сколково
                        </p>
                        <div className="row">
                            <img className="landing-contacts__img" alt="" src={logo}/>
                            <img className="landing-contacts__img2" alt="" src={sk}/>
                        </div>
                    </div>

                    <div className="landing-contacts__form-wrapper">
                        <div className="landing-contacts__form">
                            <div className="landing-contacts__form-steps">
                                <span>Шаг {step}</span>
                                <span>из 4</span>
                            </div>
                            {step === 1 && <div className="landing-contacts__input-group">
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="name">Имя</label>
                                    <input placeholder="Имя" id="name" type="text" name="name"
                                           onChange={changeHandler} value={form.name} />
                                </div>
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="surname">Фамилия</label>
                                    <input placeholder="Фамилия" id="surname" type="text" name="surname"
                                           onChange={changeHandler} value={form.surname} />
                                </div>
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="surname">Отчество</label>
                                    <input placeholder="Отчество" id="secondName" type="text" name="secondName"
                                           onChange={changeHandler} value={form.secondName} />
                                </div>
                            </div>}

                            {step === 2 && <div className="landing-contacts__input-group">
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="tel">Номер телефона</label>
                                    <input placeholder="Номер телефона" id="tel" type="text" name="tel"
                                           onChange={changeHandler} value={form.tel} />
                                </div>
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="email">Эл. почта</label>
                                    <input placeholder="Эл. почта" id="email" type="text" name="email"
                                           onChange={changeHandler} value={form.email} />
                                </div>
                            </div>}

                            {step === 3 && <div className="landing-contacts__input-group">
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="institutionType">Тип образовательное учереждения</label>
                                    <input placeholder="Введите тип учереждения"
                                           id="institutionType" type="text" name="institutionType"
                                           onChange={changeHandler} value={form.institutionType} />
                                </div>
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="institution">Образовательное учереждение</label>
                                    <input placeholder="Выберите учереждение" id="institution" type="text" name="institution"
                                           onChange={changeHandler} value={form.institution} />
                                </div>
                            </div>}

                            {step === 4 && <div className="landing-contacts__input-group">
                                <div className="landing-contacts__input-field">
                                    <label htmlFor="education">Запрос</label>
                                    <input placeholder="Опишите ваш запрос"
                                           id="education" type="text" name="education"
                                           onChange={changeHandler} value={form.education} />
                                </div>
                                <div className="landing-contacts__col">
                                    <div className="animatedCheckbox">
                                        <div style={firstCheckboxChecked ? {backgroundColor: '#2330BA'} : {}} onClick={firstCheckboxHandler} className="checkbox">
                                            <FaCheck style={{filter: 'brightness(0) invert(1)'}}/>
                                        </div>
                                        <p>Настоящим я подтверждаю, что даю согласие на <a href={policy} download="download">обработку моих персональных данных</a> для целей заключения и исполнения Пользовательского соглашения*</p>
                                    </div>
                                    <div className="animatedCheckbox">
                                        <div style={secondCheckboxChecked ? {backgroundColor: '#2330BA'} : {}} onClick={secondCheckboxHandler} className="checkbox">
                                            <FaCheck style={{filter: 'brightness(0) invert(1)'}}/>
                                        </div>
                                        <p>Настоящим я подтверждаю, что даю согласие на <a href={policy} download="download">обработку моих персональных данных</a> для целей информирования о новых продуктах
                                            и услугах, специальных предложениях и различных событиях, связанных
                                            с деятельностью компании ООО «ЛССР»</p>
                                    </div>
                                </div>
                            </div>}

                            <div className="landing-contacts__form-note">
                                Ваши данные находятся в полной сохранности. Сайт использует сертификат безопасности.</div>
                            <div className="landing-contacts__form-btns">
                                {step !== 1 && <button
                                    onClick={() => setStep(step - 1)}
                                    className="landing-contacts__form-btn landing-contacts__form-btn_back">
                                    Назад</button>}
                                {step !== 4 || (firstCheckboxChecked && secondCheckboxChecked)
                                    ?
                                    <button
                                        onClick={forwardHandler}
                                        className="landing-contacts__form-btn landing-contacts__form-btn_forward">
                                        {step === 4 ? 'Отправить' : 'Далее'}</button>
                                    :
                                    ''
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <footer className="landing-contacts__footer">
                    <div style={{marginRight: 'auto'}} className="landing-contacts__col">
                        <p className="landing-contacts__p">2022 ООО “ЛССР”. Все права защищены</p>
                        <div className="landing-contacts__row landing-contacts__row1">
                            <img className="landing-contacts__img1" src={exportIcon}/>
                            <p className="landing-contacts__p1">ПО возможно использовать на условиях безвозмездного лицензионного договора.</p>
                        </div>
                        <div className="landing-contacts__row landing-contacts__row2">
                            <img className="landing-contacts__img3" src={planet}/>
                            <p className="landing-contacts__p2">ПО распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.</p>
                        </div>
                    </div>
                    <div className="landing-contacts__col">


                        <div className="landing-contacts__row landing-contacts__row3">
                            <Link to="/">Условия пользования</Link>
                            <a href={userInstr} download="download">Руководство пользователя</a>
                            <a href={policy} download="download">Политика обработки персональных данных</a>

                        </div>


                        <p className="landing-contacts__p4">
                            Вы можете задать нам любой вопрос: <br/><a href="mailto:ceo@testu.online">ceo@testu.online</a>
                        </p>

                    </div>
                </footer>
            </section>

            <section style={showPopup ? {display: 'flex'} : {display: 'none'}} className="landing-popup" id="landing-popup">
                <FreemiumBanner showPopup={showPopupHandler}/>
            </section>
            <section className={isMenuOpened ? "opened menu" : "menu"} id="menu">
                <div className="menu__row">
                    <img src={whiteLogo}/>
                    <button onClick={() => {menuCloseHandler()}} className="menu__close"><img src={close}/></button>
                </div>
                <div className="menu__content">
                    <div onClick={() => {menuCloseHandler()}} className="menu__links">
                        <a href="#landing-main">Главная</a>
                        <a href="#landing-about">Наше решение</a>
                        <a href="#forWhatAnchor">Для кого?</a>
                        <a href="#quizAnchor">Наша методология</a>
                        <a href="#landing-goal">О компании</a>
                    </div>
                    <div style={isMenuLoginOpened ? {marginTop: '40px'} : {display: 'none'}} onClick={() => {menuCloseHandler()}} className="menu__links hidden">
                        {/*<a href="https://constructor.testu.online">Перейти к конструктору</a>*/}
                        <Link to="/pupil/login">Вход для школьника</Link>
                        <Link to="/psych/login">Вход для психолога</Link>
                    </div>
                    <button style={isMenuLoginOpened ? {display: 'none'} : {}} onClick={() => {menuLoginOpenHandler()}}><div className="menu__loginBtn">Вход в систему</div></button>
                    <button onClick={() => {showPopupHandler()}}><div className="menu__freeBtn">Начать бесплатно</div></button>

                    <div className="menu__contacts">
                        <div className="menu__contacts-row">
                            <div className="menu__contacts-imgHolder">
                                <img src={email}/>
                            </div>
                            <a href="mailto:ceo@testu.online">ceo@testu.online</a>
                        </div>
                        <div className="menu__contacts-row">
                            <div className="menu__contacts-imgHolder">
                                <img src={tel}/>
                            </div>
                            <a href="tel:+7 966 300 69 96">+7 966 300 69 96</a>
                        </div>
                        <div className="menu__contacts-row">
                            <div className="menu__contacts-imgHolder">
                                <img src={loc}/>
                            </div>
                            <a href="tel:+7 966 300 69 96">Берсеневская набережная, 6
                                строение 3</a>
                        </div>
                    </div>
                </div>
            </section>
            <img onClick={() => {menuOpenHandler()}} id="menuOpener" src={bars}/>
        </div>
    )
}