import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { BackButton } from '../../components/BackButton'
import { Layout } from '../../components/Layout'


const initialState = { city: '', name: '' }

export const SchoolForm = () => {
    const history = useHistory()
    const schoolId = useParams().id
    const [school, setSchool] = useState(null)
    const [form, setForm] = useState(initialState)

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`schools/${schoolId}`)
                setSchool(data)
                setForm({
                    ...initialState,
                    city: data.city,
                    name: data.name,
                })
            })()
        }
    }, [schoolId])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const submit = async e => {
        e.preventDefault()
        if (school) {
            await axios.post(`schools/${school._id}`, form)
        } else {
            await axios.post('schools/create', form)
        }

        history.goBack()
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Школы</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>{school ? 'Изменить школу' : 'Добавить школу'}</h3>
                    <div className="input-field">
                        <input
                            id="city"
                            type="text"
                            name="city"
                            placeholder="Город"
                            onChange={changeHandler}
                            value={form.city}
                        />
                        <label htmlFor="city">Город</label>
                    </div>
                    <div className="input-field">
                        <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Название"
                            onChange={changeHandler}
                            value={form.name}
                        />
                        <label htmlFor="name">Название</label>
                    </div>
                </div>

                <button
                    onClick={submit}
                    disabled={!form.city || !form.name}
                    className="main-btn">
                    {school ? 'Изменить школу' : 'Добавить школу'}
                </button>
            </div>
        </Layout>
    )
}