import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { FaPen } from 'react-icons/fa'
import { Layout } from '../../components/Layout'
import { BackButton } from '../../components/BackButton'


export const Classes = () => {
    const history = useHistory()
    const schoolId = useParams().id
    const [classes, setClasses] = useState([])

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`schools/${schoolId}/classes`)
                setClasses(data)
            })()
        }
    }, [schoolId])
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{classes[0]?.school.name}</p>
                    {schoolId && <Link
                        to={`/schools/${schoolId}`}
                        className="icon-btn page__icon-btn page__icon-btn_right">
                        <FaPen />
                    </Link>}
                </header>

                <div className="school-page__wrapper">
                    <div className="list">
                        {classes.map(item =>
                            <div
                                key={item._id}
                                onClick={() => {
                                    if (window.location.href.indexOf("/meetings/schools/") !== -1) {
                                        history.push(`/meetings/classes/${item._id}/pupils`)
                                    } else {
                                        history.push(`/classes/${item._id}/pupils`)
                                    }
                                }}
                                className="list__item">
                                <p>{item.number} {item.letter}</p>
                                {/* <p className="list__desc">учеников: {item.pupils.length}</p> */}
                                <p className="list__desc">макс: {item.max}</p>
                                <button
                                    onClick={e => {
                                        e.stopPropagation()
                                        history.push(`/classes/${item._id}/code`)
                                    }}
                                    className="school-page__qr-icon"
                                />
                            </div>
                        )}
                    </div>
                </div>

                {schoolId && <Link
                    to={`/schools/${schoolId}/classes/create`}
                    className="main-btn">
                    Добавить класс
                </Link>}
            </div>
        </Layout>
    )
}