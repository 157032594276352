import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { CREATE_MEETING, GET_MEETINGS } from '../types'


export function createMeeting(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())

            const json = await fetch('/api/meetings', {
                method: 'POST',
                body: JSON.stringify({ ...data }),
                headers: { 'Content-Type': 'application/json', }
            }, getState, dispatch)

            dispatch({ type: CREATE_MEETING, payload: json.meeting })
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'success', text: 'Встреча успешно назначена' }))

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function getMeetings(pupilId) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/meetings${pupilId ? `?pupilId=${pupilId}` : ''}`,
                { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_MEETINGS, payload: json.meetings })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function readMeetings() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            await fetch(`/api/meetings/read`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', }
                }, getState, dispatch)
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}