import React, {useCallback, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Tools } from '../components/Tools'
import { Histories } from '../components/Histories'
import { Layout } from '../components/Layout'
import { Loader } from '../components/Loader'
import { getAdvices } from '../redux/actions/setAdviceActions'
import { getSchools } from '../redux/actions/setSchoolActions'
import { getTestsBiPsychId, getTestsBiPupilId } from '../redux/actions/setTestActions'
import { getLastAlarm, createAlarm, getAlarms } from '../redux/actions/setAlarmActions'
import { getMeetings } from '../redux/actions/setMeetingActions'
import '../static/scss/main.scss'
import {getPupils} from "../redux/actions/setPupilsAction";

export const ProfilePage = () => {
    const dispatch = useDispatch()
    const { notPassedTests, tests } = useSelector(state => state.test)
    const { role, user } = useSelector(state => state.auth)
    const { schools } = useSelector(state => state.school)
    const pupils = useSelector(state => state.pupils)
    const { loading } = useSelector(state => state.app)
    const { advices } = useSelector(state => state.advice)
    const { meetings } = useSelector(state => state.meeting)
    const { lastAlarm, alarms } = useSelector(state => state.alarm)
    const [isShowedCookies, setIsShowedCookies] = useState(true);
    const [riskPupils, setRiskPupils] = useState([]);

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    let isWatchedCookies = getCookie('watchedCookies');

    useEffect(() => {
        if(isWatchedCookies){
            setIsShowedCookies(false)
        }
    }, [isWatchedCookies])

    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    function cookiesHandler(){
        setCookie('watchedCookies', 'true', '1');
        setIsShowedCookies(false);
    }

    useEffect(() => {
        if (role === 'pupil') {
            dispatch(getAdvices())
            dispatch(getTestsBiPupilId())
            dispatch(getLastAlarm())
        }
        if (role === 'psych') {
            dispatch(getAlarms())
            dispatch(getMeetings())
            dispatch(getTestsBiPsychId())
            dispatch(getPupils());
        }
        dispatch(getSchools())
    }, [dispatch, role])

    useEffect(() => {
        if (user && role === 'pupil') {
            dispatch(getMeetings(user._id))
        }
    }, [dispatch, role, user])

    const yearsOldCounter = useCallback((birthday) => {
        const nowDate = Date.now()
        const birthdayDate = new Date(birthday)
        const days = (nowDate - birthdayDate) / (60 * 60 * 24 * 1000)
        return `${Math.floor(days / 365)}`
    }, [])

    const alarmHandler = () => {
        if (!loading && (!lastAlarm || lastAlarm.readAt)) {
            return dispatch(createAlarm({ psychId: user.psych }))
        }
    }
    function returnRightWordForYears(val){
        val = parseInt(val);
        console.log(val)
        if(val <= 12){
            if(val === 1){
                return 'год'
            } else if (val <= 4 && val >= 2 ){
                return 'года'
            } else {
                return 'лет'
            }
        } else {
            if(val % 10 === 1){
                return 'год'
            } else if ((val % 10 >= 2 && val % 10 <= 4) && val !== 13 && val !== 14){
                return 'года'
            } else {
                return 'лет'
            }
        }
    }

    useEffect(() => {
        if(pupils.pupilsData){
            let currentArray = [];
            pupils.pupilsData.map((pupil) => {
                if(pupil.categories.length > 0){
                    currentArray.push(pupil);
                }
            })
            setRiskPupils(currentArray);
        }
    }, [pupils]);
    
    return (
        <Layout>
            <div className={`page page__small profile-page ${role === 'pupil' ? 'profile-page_pupil' : 'profile-page_psych'}`}>

                <header className="page__header">
                    <Link to='/settings' className="icon-btn page__icon-btn page__icon-btn_right icon-btn_settings"></Link>
                    <p className="page__title profile-page__title">{role === 'pupil' ? 'Ученик' : 'Психолог'}</p>
                </header>

                <div className="profile-page__main">
                    <div className="profile-page__info">
                        <p className="profile-page__name">{user?.name} {user?.surname}</p>
                        {user?.birthday ? <p className="profile-page__birthday">
                            {yearsOldCounter(user?.birthday)} {returnRightWordForYears(yearsOldCounter(user?.birthday))}
                        </p> : null}
                    </div>
                    <div className="profile-page__contacts">
                        {/*<button className="profile-page__btn">Привязать мессенджер</button>*/}
                    </div>
                </div>

                {isShowedCookies ?
                    <div className="cookies">
                        <div className="page">
                            <h3>Об использовании файлов cookie</h3>
                            <p>Для предоставления персонализированного содержимого и анализа использования нашего сайта на нем используются файлы cookie. Пользователь вправе в любое время самостоятельно изменить настройки приема файлов cookie в настройках своего браузера или отключить их полностью</p>
                            <button onClick={() => {cookiesHandler()}}>Понятно, спасибо</button>
                        </div>

                    </div>
                    : ''}

                {role === 'pupil' ?
                    <div className="profile-page__entities">

                        {user.psych && <div
                            onClick={alarmHandler}
                            className={`profile-page__alarm 
                        ${lastAlarm && !lastAlarm.readAt && 'profile-page__alarm_disabled'}`}>
                            <p>Хочу поговорить</p>
                            <p>{lastAlarm && !lastAlarm.readAt ?
                                'Вы уже сделали запрос' : 'Отправьте запрос психологу'}</p>
                        </div>}

                        {loading ? <Loader style={`margin-top: calc(100%/2 - 5rem);`} /> :
                            <div className="profile-page__entities-wrapper">
                                <Link to="/tests"
                                    className={`entity entity_test`}>
                                    <p className={`entity__title  entity__title_white`}>Тесты</p>
                                    {!notPassedTests && <p style={{color: 'white'}} className="entity__description">У вас пока нет тестов</p>}
                                    <span className={`entity__extra ${!notPassedTests && 'entity__extra_pupil-null'}`}>
                                        {notPassedTests}</span>
                                </Link>
                                <Link style={{background: 'linear-gradient(90deg, #4E54C8 0%, #8F94FB 100%)', boxShadow: '0px 2px 10px -3px #5056C9'}} to="/instruction" className={`entity entity_workWithMethods`}>
                                    <p className={`entity__title entity__title_white`}>Инструкция</p>
                                    {<span className="entity__extra entity__extra_workWithMethods"></span>}
                                </Link>
                                <Link to="/advices"
                                      style={{background: 'linear-gradient(224.06deg, #01C4FF 11.86%, #0174FF 80.15%)'}}
                                    className={`entity entity_riskGroups`}>

                                    <p className={`entity__title entity__title_white`}>Рекомендации</p>
                                    <p className={`entity__description entity__description_white`}>
                                        Индивидуально для тебя</p>
                                    <span className={`entity__extra ${(!advices.length || !advices[0].readAt) && 'entity__extra_pupil-null'}`}>
                                        {advices.length}</span>
                                </Link>
                            </div>}
                    </div>
                    :
                    <div className="profile-page__entities">
                        <Link
                            to="/alarms"
                            className={`profile-page__psych-alarm 
                        ${alarms[0] && !alarms[0].readAt
                                && 'profile-page__psych-alarm_notice'}`}>
                            <div>
                                <p>Запросы на разговор</p>
                                <p>Посмотрите, кто хочет поговорить</p>
                            </div>
                            <span>{alarms.length}</span>
                        </Link>
                        {loading ? <Loader /> :
                            <div className="profile-page__entities-wrapper">
                                <Link to="/schools" className={`entity ${!!schools.length && 'entity_school'}`}>
                                    <p className={`entity__title ${!!schools.length && 'entity__title_white'}`}>Школы</p>
                                    {!schools.length && <p className="entity__description">Добавьте школу</p>}
                                    <span className={`entity__extra ${!schools.length && 'entity__extra_psych-null'}`}>
                                        {!!schools.length && schools.length}</span>
                                </Link>
                                <Link to="/instruction" className={`entity ${!!tests.length && 'entity_workWithMethods'}`}>
                                    <p className={`entity__title ${tests.length ? 'entity__title_white' : null}`}>Инструкция</p>
                                    {!!tests.length && <span className="entity__extra entity__extra_workWithMethods"></span>}
                                </Link>
                                <Link to="/statistic/schools" className={`entity ${!!tests.length && 'entity_statistic'}`}>
                                    <p className={`entity__title ${tests.length ? 'entity__title_white' : null}`}>Результаты психодиагностики</p>
                                    {!tests.length && <p className="entity__description">Нет данных для анализа</p>}
                                    {!!tests.length && <span className="entity__extra entity__extra_statistic"></span>}
                                </Link>

                                <Link to="/riskGroups" className={`entity ${!!tests.length && 'entity_riskGroups'}`}>
                                    <p className={`entity__title ${tests.length ? 'entity__title_white' : null}`}>Группы риска</p>
                                    <span className={`entity__extra ${!riskPupils.length && 'entity__extra_psych-null'}`}>
                                        {!!riskPupils.length && riskPupils.length}</span>
                                </Link>
                            </div>
                        }

                    </div>
                }
                {role === 'psych' && <Histories />}
            </div>
        </Layout>
    )
}