import { GET_ADVICES, CREATE_ADVICE } from './types'


const initialState = {
    advices: []
}

export const adviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADVICES:
            return { ...state, advices: action.payload }
        case CREATE_ADVICE:
            return { ...state, advices: state.advices.concat([action.payload]) }
        default:
            return state
    }
}