import React,{ useState } from 'react'
import axios from 'axios'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { IconContext } from 'react-icons'
import reportWebVitals from './reportWebVitals'
import { rootReducer } from './redux/rootReducer'
import './static/scss/index.scss'
import App from './App'


axios.defaults.baseURL = `/api/`
axios.defaults.withCredentials = true

const store = createStore(rootReducer, compose(
  applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
))

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <IconContext.Provider value={{ className: 'reactIcon' }}>
        <App />
      </IconContext.Provider>
    </Provider>
  </React.StrictMode>
)

render(app, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
