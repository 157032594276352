import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from './Alert'
import { Navbar } from './Navbar'


export const Layout = ({ children }) => {
    const dispatch = useDispatch()
    const { alert } = useSelector(state => state.app)
    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get('auth/user')
                dispatch({ type: 'AUTH/SET_USER', payload: data })
            } catch (e) {
                setRedirect(true)
            }
        })()
    }, [dispatch])

    if (redirect) {
        return <Redirect to={'/'} />
    }

    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {children}
            <Navbar />
        </div>
    )
}