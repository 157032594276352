import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import { Info } from '../components/Info'
import { Loader } from '../components/Loader'
import { showAlert, hideAlert } from '../redux/actions/setAppActions'
import { getConditions } from '../redux/actions/setConditionActions'
import { getTestsBiPupilId } from '../redux/actions/setTestActions'
import axios from 'axios'
import { Layout } from '../components/Layout'



export const TestsPage = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const [info, setInfo] = useState(null)
    const [isCreate, setIsCreate] = useState(false)
    const { loading } = useSelector(state => state.app)
    const { role } = useSelector(state => state.auth)
    const { tests, conditions } = useSelector(state => state.test)
    const paramId = useParams().id;
    const [isUrlChecked, setIsUrlChecked] = useState(false);

    function goBack(){
        if(role === 'pupil'){
            window.location.href='/dashboard';
        } else {
            history.goBack()
        }
    }
    useEffect(() => {
        let iter;
        for(let i = 0; i < tests.length; i++){
            if(tests[i].conditionId === paramId){
                iter = i;
            }
        }
        if(location.pathname.includes('instruction') && iter){
            setInfo({
                id: tests[iter]._id,
                name: tests[iter].condition.name,
                desc: tests[iter].condition.desc,
                instruction: tests[iter].condition.message,
                solution: tests[iter].solution,
                conditionId: tests[iter].condition._id,
                isVisible: tests[iter].isVisible
            })
        }
        setIsUrlChecked(true)
    }, [tests])
    useEffect(() => {
        const query = new URLSearchParams(location.search)
        dispatch(getTestsBiPupilId(query.get('pupilId')))
    }, [dispatch, location])

    useEffect(() => {
        dispatch(getConditions())
    }, [dispatch])

    const formatDate = useCallback(date => {
        return new Date(date).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        })
    }, [])

    const infoBtnHandler = useCallback(e => {
        if (!info) {
            e.stopPropagation()
            const iter = e.currentTarget.id
            return setInfo({
                id: tests[iter]._id,
                name: tests[iter].condition.name,
                desc: tests[iter].condition.desc,
                instruction: tests[iter].condition.message,
                solution: tests[iter].solution,
                conditionId: tests[iter].condition._id,
                author: tests[iter].condition.methodAuthor,
                message: tests[iter].condition.message,
                desTeacher: tests[iter].condition.desTeacher,
                scales: tests[iter].condition.scales,
                fromTests: true,
                isVisible: tests[iter].isVisible
            })
        }

        setInfo(null)

    }, [info, tests])


    const isCreateHandler = () => setIsCreate(!isCreate)

    const createTestHandler = async (e) => {
        try {
            const query = new URLSearchParams(location.search)
            const pupilId = query.get('pupilId')
            const classId = query.get('classId')
            const schoolId = query.get('schoolId')
            const { data } = await axios.post('tests/create', {
                pupils: [pupilId],
                classId,
                schoolId,
                conditionId: e.currentTarget.id,
                isVisible: true
            })

            dispatch(showAlert({ type: 'success', text: data.message }))

            setTimeout(() => {
                dispatch(hideAlert())
            }, 2000)
            dispatch(getTestsBiPupilId(pupilId))
            setIsCreate(!isCreate)

        } catch (e) {
            if (e.response.data.message) {
                dispatch(showAlert({ type: 'error', text: e.response.data.message }))
            } else {
                dispatch(showAlert({ type: 'error', text: e.message }))
            }
        }
    }

    const createTestInfoHandler = e => {
        e.stopPropagation()
        const i = e.currentTarget.id
        setInfo({
            name: conditions[i].name,
            desc: conditions[i].message,
            conditionId: conditions[i]._id,
            author: conditions[i].methodAuthor,
            desTeacher: conditions[i].desTeacher,
            message: conditions[i].message,
            scales: conditions[i].scales,
            isVisible: conditions[i].isVisible
        })
    }

    if (loading || !tests || !isUrlChecked) {
        return <Loader />
    }
    if (info) {
        return (
            <Layout>
                <Info {...info} handler={infoBtnHandler}/>
            </Layout>
        )
    }




    console.log(tests)
    return (
        <Layout>
            <div className="page page__small tests-page">
                <header className="page__header">
                    <button
                        onClick={goBack}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />


                    <p className="page__title tests-page__title">
                        Тесты
                    </p>
                </header>

                <div className="tests-page__main">
                    <div>
                        <p className="tests-page__main-title">Не пройденные</p>
                        <div className="list">
                            {tests.map((test, index) => {
                                if(test){
                                    if (!test.solutionToken) {
                                        if (!test.condition) return null
                                        return (
                                            <div
                                                id={index}
                                                key={index}
                                                className="list__item"
                                                onClick={e => {infoBtnHandler(e)}}>
                                                <p>{test.condition.name}</p>
                                            </div>)
                                    }
                                }

                                return null
                            })}
                        </div>
                    </div>
                </div>

                <div className="tests-page__passed">
                    <p className="tests-page__passed-title">Пройденные тесты</p>
                    <div className="list">
                        {tests.map((test, index) => {
                            if (test.solutionToken) {
                                if (!test.condition) return null
                                return (
                                    <div key={index} className="tests-page__passed-test" style={{ position: 'relative' }}>
                                        <p className="list__desc tests-page__date">{formatDate(test.solutionDate)}</p>
                                        {test.isVisible && <span className='list__desc'
                                            style={{ position: 'absolute', top: 0, right: 0, }}>
                                            Результат доступен</span>}
                                        <div
                                            className="list__item"
                                            onClick={
                                                () => { (role === 'psych') && history.push(`/solutions/${test._id}`) }}>

                                            <p>{test.condition.name}</p>



                                        </div>
                                    </div>)
                            }
                            return null
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}