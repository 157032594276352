import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Layout } from "../../components/Layout"
import { Range } from "../../components/Range"
import { BackButton } from "../../components/BackButton"
import {SchoolFilters} from "../schools/Schools";
import search from "../../static/img/ic-search.svg";


export const StatisticSchools = () => {
    const [schools, setSchools] = useState([])
    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState([]);
    const [schoolsCities, setSchoolsCities] = useState([]);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [searchField, setSearchField] = useState('');
    const [afterSearch, setAfterSearch] = useState([]);
    function searchHandler(e){
        setSearchField(e.target.value);
    }

    useEffect(() => {
        (async () => {
            const { data } = await axios.get('statistic/schools')
            setSchools(data)
            setFilteredSchools(data)
        })()
    }, [])

    useEffect(() => {
        if(schools.length > 0){
            for(let i =0; i < schools.length; i++){
                let isAdd = true;
                schoolsCities.map((item) => {
                    if(schools[i].city === item){
                        isAdd = false;
                    }
                })
                if(isAdd){
                    let array = schoolsCities;
                    let filtersArray = filters;
                    array.push(schools[i].city);
                    filtersArray.push({
                        city: schools[i].city,
                        active: false
                    })
                    setSchoolsCities(array)
                    setFilters(filtersArray)
                }
            }
        }
    }, [schools]);

    useEffect(() => {
        let length = 0;
        filters.map((filter) => {
            if(filter.active){
                length++;
            }
        })
        if(length > 0){
            let filteredArray = [];
            schools.map((school) => {
                filters.map((filter) => {
                    if((school.city === filter.city) && filter.active){
                        filteredArray.push(school);
                    }
                })
            })
            setFilteredSchools(filteredArray);
        } else {
            setFilteredSchools(schools);
        }


    }, [filters])

    useEffect(() => {
        console.log(filteredSchools)
        if(searchField.length > 0){
            let newSchools = [];
            for(let school of filteredSchools){
                let name = school.name;
                let slicedName = name.slice(0, searchField.length)
                let city = school.city;
                let slicedCity = city.slice(0, searchField.length)
                console.log(slicedCity + "....." + searchField)
                if(slicedName.toLowerCase() === searchField.toLowerCase() || slicedCity.toLowerCase() === searchField.toLowerCase()){
                    newSchools.push(school)
                }
            }
            setAfterSearch(newSchools)

        } else {
            setAfterSearch(filteredSchools)
        }
    }, [searchField, filteredSchools])

    if(isFilters){
        return <SchoolFilters setFilters={setFilters} filters={filters} setIsFilters={setIsFilters}/>
    }
    console.log(schools)

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Статистика</p>
                    <button
                        onClick={() => {setIsFilters(true)}}
                        className={`icon-btn page__icon-btn page__icon-btn_right icon-btn_filter`} >
                    </button>
                </header>

                <div>
                    <div className="school-page__search">
                        <img src={search}/>
                        <input onChange={searchHandler} value={searchField} placeholder="Поиск"/>
                    </div>
                    {afterSearch.map((school, index) => (
                        <Link
                            key={index}
                            className="statistic-condition"
                            to={`/statistic/schools/${school._id}`}>
                            <p style={{marginBottom: 10}} className="statistic-condition__title">
                                {school.name}
                            </p>
                            <p style={{textAlign: 'left'}} className="statistic-condition__members">
                                {school.city}
                            </p>
                        </Link>
                    ))}
                </div>
            </div>
        </Layout>
    )
}