import React, { useState, useEffect } from "react"
import { useParams, Redirect, Link } from "react-router-dom"
import axios from 'axios'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { FaRegTrashAlt } from "react-icons/fa"
import { BiUser } from "react-icons/bi"
import { BackButton } from "../../components/BackButton"
import { Layout } from "../../components/Layout"
import {SureAlert} from "../../components/SureAlert";


const initialState = {
    name: '',
    date: '',
    description: ''
}

export const MeetingForm = () => {
    const { pupilId, meetingId } = useParams()
    const [redirect, setRedirect] = useState(false)
    const [form, setForm] = useState(initialState)
    const [meeting, setMeeting] = useState(null)

    const { role } = useSelector(state => state.auth)
    const [isSureAlert, setIsSureAlert] = useState(false)
    function onSendClick(event) {
        setIsSureAlert(true);
    }
    function onCloseClick(event) {
        setIsSureAlert(false);
    }
    useEffect(() => {
        if (meetingId) {
            (async () => {
                const { data } = await axios.get(`meetings/${meetingId}`)
                const { name, description, date } = data
                setMeeting(data)
                setForm({
                    ...initialState,
                    name, description,
                    date: moment(date).format("YYYY-MM-DDTkk:mm")
                })
            })()
        }
    }, [meetingId])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const submitHandler = async e => {
        e.preventDefault()

        if (meetingId) {
            await axios.put(`meetings/${meetingId}`, form)
        } else {
            await axios.post('meetings', { ...form, pupilId })
        }

        setRedirect(true)
    }

    const deleteHandler = async (e) => {
        e.preventDefault()

        if ((window.confirm("Do you really delete this item?"))) {
            await axios.delete(`meetings/${meetingId}`)
            setRedirect(true)
        }
    }

    if (redirect) {
        return <Redirect to={pupilId ? `/pupils/${pupilId}/meetings` : '/meetings'} />
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Встречи</p>
                    {role === "psych" ? (meeting?.pupil && <Link
                        to={`/pupils/${meeting.pupil}`}
                        style={{ padding: '8px' }}
                        className="icon-btn page__icon-btn page__icon-btn_right">
                        <BiUser />
                    </Link>) : <Link
                        to={`/dashboard`}
                        style={{ padding: '8px' }}
                        className="icon-btn page__icon-btn page__icon-btn_right">
                        <BiUser />
                    </Link>}
                </header>

                <div className="school-page__wrapper">
                    <h3>Детали встречи</h3>
                    <div className="input-field input-field_purple">
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={form.name}
                            placeholder="Тема встречи"
                            onChange={changeHandler}
                            readOnly={role === 'pupil'}
                        />
                        <label htmlFor="number">Тема</label>
                    </div>
                    <div className="input-field input-field_purple">
                        <input
                            id="description"
                            type="text"
                            name="description"
                            value={form.description}
                            onChange={changeHandler}
                            placeholder="Подробности встречи"
                            readOnly={role === 'pupil'}
                        />
                        <label htmlFor="letter">Описание и место</label>
                    </div>
                    <div className="input-field input-field_purple">
                        <input
                            id="date"
                            name="date"
                            value={form.date}
                            type="datetime-local"
                            onChange={changeHandler}
                            readOnly={role === 'pupil'}
                        />
                        <label htmlFor="birthday">Дата встречи</label>
                    </div>
                </div>

                {role === 'psych' && <button
                    onClick={onSendClick}
                    disabled={!form.name || !form.date}
                    className="main-btn main-btn_purple">
                    {meetingId ? 'Изменить' : 'Назначить'}
                </button>}
                {role === 'psych' && <button
                    style={{
                        color: 'fff',
                        width: '58px',
                        height: '58px',
                        margin: '1.5rem auto',
                        backgroundColor: '#FE2B2B',
                        borderRadius: '50%',
                        boxShadow: '0px 2px 12px rgb(0 110 255 / 40%)',
                    }}
                    onClick={deleteHandler}>
                    <FaRegTrashAlt color="fff" />
                </button>}
            </div>
            {isSureAlert ? <SureAlert type='createMeeting' handleClose={onCloseClick} handleSubmit={e => {submitHandler(e); onCloseClick()}}/> : ''}
        </Layout>
    )
}