import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, updateUser } from '../redux/actions/setUserActions'
import { ReactComponent as PersonSVG } from '../static/img/ic-person.svg'
import { ReactComponent as LockSVG } from '../static/img/ic-lock.svg'
import { ReactComponent as DialogSVG } from '../static/img/ic-dialog.svg'
import { Loader } from '../components/Loader'
import { BackButton } from '../components/BackButton'
import axios from 'axios'
import { Layout } from '../components/Layout'


export const SettingsPage = () => {
    const dispatch = useDispatch()
    const [section, setSection] = useState('')

    const callback = useCallback(() => {
        setSection('')
    }, [])

    if (section === 'personalData') {
        return <PersonalData callback={callback} />
    }

    const logout = async () => {
        await axios.post('auth/logout')
        dispatch({
            type: 'AUTH/SET_USER', payload: {
                user: null, role: null
            }
        })
    }

    return (
        <Layout>
            <div style={{paddingBottom: '54px'}} className="page settings-page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title settings-page__title">Настройки профиля</p>
                </header>
                <div className="settings-page__main">
                    {/*<button className="settings-page__btn">Изменить фото профиля</button>*/}
                </div>
                <div className="settings-page__sections">
                    <div className="settings-page__sections-wrapper">
                        <p className="settings-page__sections-title">Профиль</p>
                        <div className="settings-sections">
                            <p onClick={() => setSection('personalData')}
                                className="settings-sections__item">
                                <PersonSVG />
                                Персональные данные
                            </p>
                            {/*<Link*/}
                            {/*    to="/settings/security"*/}
                            {/*    className="settings-sections__item">*/}
                            {/*    <LockSVG />*/}
                            {/*    Безопасность и вход*/}
                            {/*</Link>*/}
                            {/*<p className="settings-sections__item">*/}
                            {/*    <DialogSVG />*/}
                            {/*    Привязка мессенджеров*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="settings-page__footer">
                        <Link
                            to={'/'}
                            onClick={logout}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            className="logout-btn">
                            Выйти из учетной записи
                        </Link>
                        <p style={{marginTop: '3rem',opacity: 0.3}}>По любым вопросам технических сбоев или проблем при пользовании платформой Вы можете обратиться по почте help@testu.online и мы оперативно решим ваш вопрос</p>
                        <p style={{marginTop: '1rem', opacity: 0.3}}>По любым вопросам предложений по развитию платформы Вы можете обратиться по почте school@testu.online и мы оперативно рассмотрим ваше предложение</p>
                    </div>
               </div>
            </div>
        </Layout>
    )
}


const PersonalData = ({ callback }) => {
    const dispatch = useDispatch()
    const [key, setKey] = useState('')
    const { user, role } = useSelector(state => state.auth)
    const { loading } = useSelector(state => state.app)

    const [form, setForm] = useState({})

    useEffect(() => {
        setForm({
            sex: user.sex,
            name: user.name,
            surname: user.surname,
            birthday: user.birthday,
            email: user.email,
            city: user.city,
            brigade: user.brigade,
            region: user.region,
            institution: user.institution,
            phone: user.phone,
            contact: user.contact,
            patronymic: user.patronymic
        })
    }, [user])

    const formatBirthday = useCallback((date) => {
        const newDate = new Date(date)
        return newDate.toLocaleDateString()
    }, [])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    if (loading) {
        return <Loader />
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={!key ? () => callback() : () => setKey('')}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back">
                    </button>
                </header>
                <div className="page__content">
                    {!key && <h3>Персональные данные</h3>}
                    {key === 'sex' && <h3>Пол</h3>}
                    {key === 'name' && <h3>ФИО</h3>}
                    {key === 'birthday' && <h3>Дата рождения</h3>}

                    {key === 'sex' &&
                        <div className="auth-page__input-group auth-page__input-group_row">
                            <div className="input-field">
                                <input id="m" type="radio" checked={form.sex === '1'}
                                    name="sex" value='1' onChange={changeHandler} />
                                <label htmlFor="m">Мужской</label>
                            </div>
                            <div className="input-field">
                                <input id="f" type="radio" checked={form.sex === '2'}
                                    name="sex" value='2' onChange={changeHandler} />
                                <label htmlFor="f">Женский</label>
                            </div>
                        </div>
                    }

                    {key === 'name' &&
                        <div className="auth-page__input-group">
                            <div className="input-field">
                                <input placeholder="Фамилия" id="surname" type="text" name="surname"
                                    onChange={changeHandler} value={form.surname} />
                                <label htmlFor="surname">Фамилия</label>
                            </div>
                            <div className="input-field">
                                <input placeholder="Имя" id="name" type="text" name="name"
                                    onChange={changeHandler} value={form.name} />
                                <label htmlFor="name">Имя</label>
                            </div>
                            <div className="input-field">
                                <input placeholder="Отчество" id="patronymic" type="text" name="patronymic"
                                    onChange={changeHandler} value={form.patronymic} />
                                <label htmlFor="patronymic">Отчество</label>
                            </div>
                        </div>
                    }

                    {key === 'birthday' &&
                        <div className="auth-page__input-group">
                            <div className="input-field">
                                <input id="birthday" type="date" name="birthday"
                                    onChange={changeHandler} value={form.birthday} />
                                <label htmlFor="birthday">Дата рождения</label>
                            </div>
                        </div>
                    }
                    {key === 'city' &&
                        <div className="auth-page__input-group">
                            <h3 style={{textAlign: "left"}}>Город</h3>
                            <div className="input-field">
                                <input placeholder="Город" id="city" type="text" name="city"
                                       onChange={changeHandler} value={form.city} />
                                <label htmlFor="city">Город</label>
                            </div>
                        </div>
                    }
                    {key === 'region' &&
                        <div className="auth-page__input-group">
                            <h3 style={{textAlign: "left"}}>Регион</h3>
                            <div className="input-field">
                                <input placeholder="Регион" id="region" type="text" name="region"
                                       onChange={changeHandler} value={form.region} />
                                <label htmlFor="region">Регион</label>
                            </div>
                        </div>
                    }
                    {key === 'brigade' &&
                        <div className="auth-page__input-group">
                            <h3 style={{textAlign: "left"}}>Бригада</h3>
                            <div className="input-field">
                                <input placeholder="Бригада" id="brigade" type="text" name="brigade"
                                       onChange={changeHandler} value={form.brigade} />
                                <label htmlFor="brigade">Бригада</label>
                            </div>
                        </div>
                    }
                    {key === 'institution' &&
                        <div className="auth-page__input-group">
                            <h3 style={{textAlign: "left"}}>Учреждение</h3>
                            <div className="input-field">
                                <input placeholder="Учреждение" id="institution" type="text" name="institution"
                                       onChange={changeHandler} value={form.institution} />
                                <label htmlFor="institution">Учреждение</label>
                            </div>
                        </div>
                    }
                    {key === 'phone' &&
                        <div className="auth-page__input-group">
                            <h3 style={{textAlign: "left"}}>Телефон</h3>
                            <div className="input-field">
                                <input placeholder="Телефон" id="phone" type="text" name="phone"
                                       onChange={changeHandler} value={form.phone} />
                                <label htmlFor="phone">Телефон</label>
                            </div>
                        </div>
                    }
                    {key === 'email' &&
                        <div className="auth-page__input-group">
                            <h3 style={{textAlign: "left"}}>Почта</h3>
                            <div className="input-field">
                                <input placeholder="Почта" id="email" type="text" name="email"
                                       onChange={changeHandler} value={form.email} />
                                <label htmlFor="email">Почта</label>
                            </div>
                        </div>
                    }
                    {key === 'contact' &&
                        <div className="auth-page__input-group">
                            <h3 style={{textAlign: "left"}}>Контакты</h3>
                            <div className="input-field">
                                <textarea placeholder="Контакты" id="contact" type="text" name="contact"
                                       onChange={changeHandler} value={form.contact} />
                                <label htmlFor="contact">Контакты</label>
                            </div>
                        </div>
                    }

                    {!key &&
                        <ul className="settings-details">
                            <li onClick={() => setKey('sex')}
                                className="settings-details__item">
                                <p>Пол</p>
                                {!user.sex && <p>Пол не указан</p>}
                                {!!user.sex && user.sex === '1' && <p>Мужской</p>}
                                {!!user.sex && user.sex === '2' && <p>Женский</p>}
                            </li>
                            <li onClick={() => setKey('name')}
                                className="settings-details__item">
                                <p>ФИО</p>
                                <p>{user.surname} {user.name} {user.patronymic}</p>
                            </li>
                            <li onClick={() => setKey('birthday')}
                                className="settings-details__item">
                                <p>Дата рождения</p>
                                <p>{formatBirthday(user.birthday)}</p>
                            </li>
                            {role === 'psych' ? <>
                                <li onClick={() => setKey('city')}
                                    className="settings-details__item">
                                    <p>Город врача</p>
                                    <p>{user.city}</p>
                                </li>
                                <li onClick={() => setKey('region')}
                                    className="settings-details__item">
                                    <p>Регион</p>
                                    <p>{user.region}</p>
                                </li>
                                <li onClick={() => setKey('brigade')}
                                    className="settings-details__item">
                                    <p>Бригада</p>
                                    <p>{user.brigade}</p>
                                </li>
                                <li onClick={() => setKey('institution')}
                                    className="settings-details__item">
                                    <p>Учреждение</p>
                                    <p>{user.institution}</p>
                                </li>
                                <li onClick={() => setKey('phone')}
                                    className="settings-details__item">
                                    <p>Телефон</p>
                                    <p>{user.phone}</p>
                                </li>
                                <li
                                    className="settings-details__item">
                                    <p>Почта</p>
                                    <p>{user.email}</p>
                                </li>
                            </> : ''}
                            {role === 'pupil' ? <>
                                <li onClick={() => setKey('contact')}
                                    className="settings-details__item">
                                    <p>Контакты родителя</p>
                                    <p>{user.contact}</p>

                                </li>
                            </> : ''}

                        </ul>
                    }
                </div>
                {!!key && <button
                    onClick={() => dispatch(updateUser(form))}
                    className='main-btn'>Сохранить</button>}
            </div>
        </Layout>
    )
}