import { Link, useParams } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from '../components/Loader'
import { BackButton } from '../components/BackButton'
import { getPupil } from '../redux/actions/setPupilActions'
import { getTestsBiPupilId } from '../redux/actions/setTestActions'
import { getNotes } from '../redux/actions/setNoteActions'
import { getAdvices } from '../redux/actions/setAdviceActions'
import { getMeetings } from '../redux/actions/setMeetingActions'
import { Layout } from '../components/Layout'


export const PupilPage = () => {
    const pupilId = useParams().id
    const dispatch = useDispatch()
    const { notPassedTests } = useSelector(state => state.test)
    const { role } = useSelector(state => state.auth)
    const { pupilData } = useSelector(state => state.pupil)
    const { advices } = useSelector(state => state.advice)
    const { meetings } = useSelector(state => state.meeting)
    const { notes } = useSelector(state => state.note)
    const { loading } = useSelector(state => state.app)

    useEffect(() => {
        dispatch(getTestsBiPupilId(pupilId))
        dispatch(getAdvices(pupilId))
        dispatch(getMeetings(pupilId))
        dispatch(getNotes(pupilId))
        dispatch(getPupil(pupilId))
    }, [dispatch, pupilId])

    const yearsOldCounter = useCallback((birthday) => {
        const nowDate = Date.now()
        const birthdayDate = new Date(birthday)
        const days = (nowDate - birthdayDate) / (60 * 60 * 24 * 1000)
        return `${Math.floor(days / 365)}`
    }, [])

    if (loading || !advices || !pupilData) {
        return <Loader />
    }

    function returnRightWordForYears(val){
        if(val <= 12){
            if(val === 1){
                return 'год'
            } else if (val >= 2 && val <= 4 ){
                return 'года'
            } else {
                return 'лет'
            }
        } else {
            if(val % 10 === 1){
                return 'год'
            } else if (val % 10 >= 2 && val % 10 <= 4 ){
                return 'года'
            } else {
                return 'лет'
            }
        }
    }

    console.log(pupilData)
    return (
        <Layout>
            <div className={`page page__small profile-page 'profile-page_pupil'`}>
                <header className="page__header">
                    <BackButton />
                    <p className="page__title profile-page__title">Ученик</p>
                </header>

                <div className="profile-page__main">
                    <div className="profile-page__info">
                        <p className="profile-page__name">{pupilData.name} {pupilData.surname}</p>
                        {pupilData.birthday
                            ? <p className="profile-page__birthday">
                                <span> {yearsOldCounter(pupilData.birthday)} {returnRightWordForYears(yearsOldCounter(pupilData.birthday))}, {pupilData.school.name}, {pupilData.school.city}</span>
                            </p>
                            : null}
                    </div>
                </div>

                <div className="profile-page__entities">
                    <Link
                        to={`/pupils/${pupilId}/categories`}
                        className={`profile-page__psych-alarm`}>
                        <div>
                            <p>Группы риска</p>
                            <p>Укажите группы риска для этого ученика</p>
                        </div>
                        <span>{pupilData.categories.length}</span>
                    </Link>

                    <div className="profile-page__entities-wrapper">
                        <Link to={`/tests?pupilId=${pupilId}&schoolId=${pupilData.class.school}&classId=${pupilData.class._id}`}
                            className={`entity ${!!notPassedTests && 'entity_test'}`}>
                            <p className={`entity__title ${!!notPassedTests && 'entity__title_white'}`}>Тесты</p>
                            {!notPassedTests && <p className="entity__description">У вас пока нет тестов</p>}
                            <span className={`entity__extra ${!notPassedTests && 'entity__extra_pupil-null'}`}>
                                {notPassedTests}
                            </span>
                        </Link>
                        <Link to={`/advices?pupilId=${pupilId}`}
                            className={`entity ${'entity_advice'}`}>
                            <p className={`entity__title ${'entity__title_white'}`}>Рекомендации</p>
                            {role === 'psych' ?
                                <p style={{lineHeight: '16px', marginTop: 5}} className={`entity__description entity__description_white`}>
                                    Данная информация будет доступна для ученика</p>
                            :''}
                            {role === 'pupil' ?
                                <p style={{lineHeight: '16px', marginTop: 5}} className={`entity__description entity__description_white`}>
                                    Индивидуально для тебя</p>
                                :''}
                            <span className={`entity__extra `}>
                                {advices.length}
                            </span>
                        </Link>
                        <Link to={`/notes?pupilId=${pupilId}`}
                            className={`entity ${'entity_note'}`}>
                            <p className={`entity__title ${'entity__title_white'}`}>Заметки</p>
                            {role === 'psych' ?
                                <p style={{lineHeight: '16px', marginTop: 5}} className={`entity__description entity__description_white`}>
                                    Данная информация не будет доступна для ученика</p>
                                :''}
                            <span className={`entity__extra`}>
                                {notes.length}
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}