import React, {useState, useEffect, useCallback} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {showAlert} from '../redux/actions/setAppActions'
import {getCondition} from '../redux/actions/setConditionActions'
import {Loader} from '../components/Loader'
import {FINISH_LOADING, START_LOADING} from '../redux/types'
import {Layout} from '../components/Layout'


export const TestPage = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const {user, role} = useSelector(state => state.auth)
    const {condition} = useSelector(state => state.test)
    const {loading} = useSelector(state => state.app)

    const [questionId, setQuestionId] = useState(0)
    const [questions, setQuestions] = useState([])
    const [question, setQuestion] = useState(null)
    const [results, setResults] = useState([])
    const [answer, setAnswer] = useState([])
    const [end, setEnd] = useState(false)
    const [chosenFields, setChosenFields] = useState([]);
    const [trigger, setTrigger] = useState(0);
    const [textAnswers, setTextAnswers] = useState([]);
    const [textSelectionAnswers, setTextSelectionAnswers] = useState([]);

    const conditionId = useParams().conditionId
    const testId = useParams().testId

    function addTextSelectionAnswer(id, value){
        let answers = textSelectionAnswers;
        answers.push({
            id: id,
            value: value
        })
        setTextSelectionAnswers(answers);
    }
    function addTextAnswer(id, value){
        let answers = textAnswers;
        answers.push({
            id: id,
            value: value
        })
        setTextAnswers(answers);

    }
    useEffect(() => {
        dispatch(getCondition(conditionId))
    }, [conditionId, dispatch])

    const postSolution = useCallback(async () => {
        if (role === 'psych') return
        dispatch({type: START_LOADING})

        const json = condition.body
        for (let result of results) {
            for (let question of json.questions) {
                for(let textAnswer of textAnswers){
                    if(textAnswer.id === question.id){
                        question.textAnswer = textAnswer.value;
                    }
                }
                for(let textAnswer of textSelectionAnswers){
                    if(textAnswer.id === question.id){
                        for (let answer of question.answers) {
                            if (answer.id === result) {
                                answer.textTitle = textAnswer.value
                            }
                        }
                    }
                }
                for (let answer of question.answers) {
                    if (answer.id === result) {
                        answer.selected = true
                    }
                }
            }
        }
        console.log(json)

        try {
            fetch('/api/solution', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .then(res => {
                    return fetch('/api/tests/solution', {
                        method: 'POST',
                        body: JSON.stringify({solution: res, rawSolution: json, testId, normStatus: true}),
                        headers: {'Content-Type': 'application/json'}
                    })
                })
                .then(() => {
                    dispatch({type: FINISH_LOADING})
                    window.location.href = `/dashboard`
                })
        } catch (e) {
            dispatch({type: FINISH_LOADING})
            dispatch(showAlert({type: 'error', text: e}))
        }

    }, [condition, user, results, testId, conditionId, dispatch])

    useEffect(() => {
        !!end && postSolution()
    }, [end, postSolution])

    useEffect(() => {
        console.log(condition)
        if (condition && condition.body) {
            const body = condition.body
            const qws =body.questions
            setQuestions(qws)
        }
    }, [condition])

    useEffect(() => {

        if(questions){
            if (questions.length) {
                const qw = questions[questionId]
                if(qw.questionType === 'MultiSelection'){
                    setQuestion({
                        name: qw.name,
                        type: 'multi',
                        quantity: qw.answerQuantity,
                        answers: qw.answers,
                    })
                } else if(qw.questionType === 'TextSelection'){
                    setQuestion({
                        id: qw.id,
                        name: qw.name,
                        type: 'textSelection',
                        answers: qw.answers,
                        textAnswer: qw.textAnswer
                    })
                } else if(qw.questionType === 'Text'){
                    setQuestion({
                        id: qw.id,
                        name: qw.name,
                        type: 'text',
                        answers: qw.answers,
                        textAnswer: qw.textAnswer
                    })
                }
                else {
                    setQuestion({
                        name: qw.name,
                        type: qw.type,
                        answers: qw.answers,
                    })
                }


                if(chosenFields.length === 0){
                    for(let i = 0; i < qw.answers.length; i++){
                        let fields = chosenFields;
                        fields.push('');
                        setChosenFields(fields)
                    }
                }



                setAnswer([])

            }
        }

    }, [conditionId, questionId, questions])

    function goBackHandler(){

        let updatedResults = [...results];
        updatedResults.pop();
        setResults(updatedResults)

        if(questionId !== 0){
            setQuestionId(questionId - 1)
        } else {
            goBack();
        }

    }
    const confirmBtnHandler = useCallback(() => {
        if(question.type === 'text'){


                console.log((questionId + 1) + ',......,' +questions.length)
                if (questionId + 1 !== questions.length) {
                    setQuestionId(questionId + 1);

                } else {
                    setEnd(!end)
                }

        }else if(question.type !== 'MultiSelection') {
            if (answer.length || role === "psych") {
                const updatedResults = [...results, ...answer]
                setResults(updatedResults)
                console.log((questionId + 1) + ',......,' +questions.length)
                if (questionId + 1 !== questions.length) {
                    setQuestionId(questionId + 1);

                } else {
                    setEnd(!end)
                }

            }
        } else {
            let multiAnswer = [];
            for(let i = 0; i < answer.length; i++){
                if(answer[i] !== ''){
                    multiAnswer.push(answer[i]);
                }
            }

            if (multiAnswer.length || role === "psych") {
                const updatedResults = [...results, ...multiAnswer]
                setResults(updatedResults)

                if (questionId + 1 !== questions.length) {
                    setQuestionId(questionId + 1)
                    setChosenFields([]);
                    setAnswer([]);
                    setTrigger(0);
                } else {
                    setEnd(!end)
                }

            }
        }

        // setValue(0)
    }, [role, answer, end, questionId, questions, results])
    const setNewAnswer = useCallback(value => {
        setAnswer([value])
    }, [])

    function changeHandler(id, index) {
        let newFields = chosenFields;
        if(newFields[index] !== id){
            if(question.quantity !== null && question.quantity !== '' && question.quantity > 0){
                if(trigger < question.quantity){
                    newFields[index] = id;
                    setTrigger(trigger + 1);
                }
            } else {
                if(trigger < question.answers.length){
                    newFields[index] = id;
                    setTrigger(trigger + 1);
                }
            }

        } else {
            newFields[index] = '';
            setTrigger(trigger - 1);
        }

        setChosenFields(newFields);
        setAnswer(newFields)


    }

    if (!question) return null

    if (loading) {
        return <Loader/>
    }

    let type
    if (question.type === "Range" || question.type === "range") {
        type = "range"
    } else if (question.type === "Selection" || question.type === 'select') {
        type = "select"

    } else if (question.type === "multi") {
        type = "multi"
    } else if(question.type === 'textSelection'){
        type = "textSelection";
    } else if(question.type === 'text'){
        type = 'text'
    }
    function goBack(){
        if(role === 'pupil'){
            window.location.href='/tests/instruction/' + conditionId;
        } else {
            history.goBack()
        }

    }
    console.log(results)


    return (
        <Layout>
            <div className="page walkthrough">
                <header className="page__header">
                    <button
                        onClick={goBackHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    <div className="walkthrough__score">
                        <p>{questionId ? questionId + 1 : '1'}</p>
                        <p>{questions ? questions.length : ''}</p>
                    </div>
                </header>

                <div className="progress-bar walkthrough__progress-bar">
                    <span className="progress-bar__progress"
                          style={{'width': `${(questionId + 1) * 100 / questions.length}%`}}
                    />
                </div>
                <p className="walkthrough__title">{question.name ? question.name : 'Выберите один вариант ответа'}</p>

                {(type === "select" && (trigger === '' || trigger )) ?
                    <div className="answers-wrapper">
                        <p className="answers-wrapper__prompt">Нажми на подходящий ответ</p>
                        <div className="answers">
                            {
                                question.answers.map((elem, index) => {
                                    return (
                                        <label className="answers__item" key={index}>
                                            <input
                                                type="radio" value={elem.id}
                                                checked={answer[0] === elem.id}
                                                onChange={e => setNewAnswer(e.target.value)}/>
                                            <span>{elem.name}</span>
                                        </label>
                                    )
                                })
                            }
                        </div>
                        <button
                            onClick={confirmBtnHandler}
                            className={`answers-wrapper__confirm-btn 
                        ${answer.length || role === 'psych' ? 'answers-wrapper__confirm-btn_active' : ''}`}
                        />
                    </div> :
                    type === 'range' ?
                    <Range
                        answer={answer}
                        question={question}
                        confirmBtnHandler={confirmBtnHandler}
                        setAnswer={setNewAnswer}
                    />
                        :  type === 'textSelection' ?
                            <TextSelection addTextAnswer={addTextSelectionAnswer} questionId={questionId} condition={condition} question={question} answer={answer} confirmBtnHandler={confirmBtnHandler} setNewAnswer={setNewAnswer}/>
                        : type === 'text' ?
                            <Text addTextAnswer={addTextAnswer} questionId={questionId} condition={condition} question={question} answer={answer} confirmBtnHandler={confirmBtnHandler} setNewAnswer={setNewAnswer} role={role}/>
                            :
                        <div className="answers-wrapper">
                            <p className="answers-wrapper__prompt">Выберите {question.quantity ? question.quantity : question.answers.length} из {question.answers.length}</p>
                            <div className="answers">
                                {
                                    question.answers.map((elem, index) => {

                                        return (
                                            <label className="answers__item" key={index}>
                                                <input
                                                    type="checkbox" value={elem.id}
                                                    checked={answer[index] === elem.id}
                                                    onChange={e => {changeHandler(e.target.value, index)}}/>
                                                <span>{elem.name}</span>
                                            </label>
                                        )
                                    })
                                }
                            </div>
                            <button
                                onClick={confirmBtnHandler}
                                className={`answers-wrapper__confirm-btn 
                        ${answer.length || role === 'psych' ? 'answers-wrapper__confirm-btn_active' : ''}`}
                            />
                        </div>
                }
            </div>
        </Layout>
    )
}


const Range = ({question, confirmBtnHandler, setAnswer}) => {
    const min = parseInt(question.answers[0].code)
    const [value, setValue] = useState(0)
    const [max, setMax] = useState(0)
    const [btnActive, setBtnActive] = useState(false)
    useEffect(() => {
        setValue(min)
        setBtnActive(false)
        if (question) {
            setMax(parseInt(question.answers[question.answers.length - 1].code))
        }
    }, [question])

    const handleChange = e => {
        setBtnActive(true)
        setValue(e.target.value)
        if(question){
            if(question.answers){
                if (question.answers[e.target.value]) {
                    setAnswer(question.answers[e.target.value].id)
                }
            }
        }
    }
    const clickHandler = (e) => {
        setBtnActive(true);
        let value = e.target.getAttribute("val");
        const input = document.getElementsByClassName('walk-range__slider')[0];
        if (input) {
            setValue(parseInt(value))
        }
        if(question){
            if(question.answers){
                if (question.answers[value]) {
                    setAnswer(question.answers[value].id)
                }
            }
        }

    }

    const useDotes = useCallback(() => {
        const dotes = []
        let number = min;
        for (var i = 0; i < question.answers.length; i++) {
            dotes.push(<li style={i >= value ? {'background': `#DEEBF9`} : null} key={i}>
                <div val={number} onClick={e => clickHandler(e)} className="walk-range__clickHandler">

                </div>
            </li>)
            number++;
        }
        return dotes
    }, [question, value])
    useEffect(() => {
        var isFirefox = typeof InstallTrigger !== 'undefined';
        if (isFirefox) {
            let progress = document.getElementsByClassName('walk-range__progress')[0];
            let dots = document.getElementsByClassName('walk-range__dotes')[0];
            if (progress) {
                progress.style.display = 'none';
            }
            if (dots) {
                dots.style.zIndex = '0';
            }
        }
    }, [])
    return (
        <div className="walk-range-wrapper">

            <div className="walk-range">
                <span className='walk-range__min'>{min}</span>
                <span className='walk-range__max'>{max}</span>
                <ul className="walk-range__dotes">{useDotes()}</ul>
                <input onChange={handleChange}
                       className="walk-range__slider"
                       type="range" min={min} max={max} value={value}/>
                <div className="walk-range__progress" style={{'width': `${(value - min) * 100 / (max - min)}%`}}/>
            </div>

            <button
                onClick={confirmBtnHandler}
                className={`walk-range-wrapper__button 
                ${btnActive ? 'walk-range-wrapper__button_active' : null}`}>
            </button>
            <p>Потяните ползунок чтобы выбрать подходящий ответ</p>
        </div>
    )
}

const TextSelection = ({question, questionId, confirmBtnHandler, setNewAnswer, answer, role, condition, addTextAnswer}) => {
    console.log(question)
    const [step, setStep] = useState(1);
    const [isText, setIsText] = useState(false);
    const [questionText, setQuestionText] = useState("");
    const [textAnswer, setTextAnswer] = useState("");

    function isTextHandler(elem){
        if(elem.isText){
            setIsText(true);
            setQuestionText(elem.textTitle)
        } else {
            setIsText(false);
        }
    }
    function testAnswerHandler(e){
        setTextAnswer(e.target.value)
    }
    function firstPartNextHandler(){
        if(isText){
            setStep(2);
        } else {
            confirmBtnHandler();
        }
    }
    async function secondPartNextHandler(){
        addTextAnswer(question.id, textAnswer);
        confirmBtnHandler();
        setStep(1);
        setTextAnswer("");
    }
    return(
        <div className="answers-wrapper">
            <p className="answers-wrapper__prompt">Нажми на подходящий ответ</p>
            <div className="answers">
                {
                    question.answers.map((elem, index) => {
                        return (
                            <label className="answers__item" key={index}>
                                <input
                                    type="radio" value={elem.id}
                                    checked={answer[0] === elem.id}
                                    onChange={e => {setNewAnswer(e.target.value);isTextHandler(elem); }}/>
                                <span>{elem.name}</span>
                            </label>
                        )
                    })
                }
            </div>
            <button
                onClick={firstPartNextHandler}
                className={`answers-wrapper__confirm-btn 
                        ${answer.length || role === 'psych' ? 'answers-wrapper__confirm-btn_active' : ''}`}
            />
            {step === 2 ?
                <div className="answers-questionBottom">
                    <Layout>
                        <div className="page">
                            <div className="answers-questionBottom__inner">
                                <h3>{questionText}</h3>
                                <input value={textAnswer} onChange={testAnswerHandler} placeholder="Введите значение"/>

                                <button
                                    onClick={secondPartNextHandler}
                                    className={`answers-wrapper__confirm-btn 
                        ${answer.length || role === 'psych' ? 'answers-wrapper__confirm-btn_active' : ''}`}
                                />
                            </div>
                        </div>
                    </Layout>

                </div>
                : ''}

        </div>
    )
}

const Text = ({questionId, question, condition, confirmBtnHandler, answer, role, addTextAnswer, setNewAnswer}) => {
    const [textAnswer, setTextAnswer] = useState('');

    function textAnswerChangeHandler(e){
        setTextAnswer(e.target.value)
    }

    async function nextHandler(){
        addTextAnswer(question.id, textAnswer);
        confirmBtnHandler();
        setTextAnswer("");
    }
    return(
        <div className="answers-textBlock">

            <div className="input-field">
                <textarea
                    style={{marginBottom: 150}}
                    id="text_answer"
                    type="text"
                    name="text_answer"
                    placeholder="Введите свой ответ"
                    onChange={textAnswerChangeHandler}
                    value={textAnswer}
                />
                <label htmlFor="text_answer">Введите свой ответ</label>
            </div>
            <button
                onClick={nextHandler}
                className={`answers-wrapper__confirm-btn 
                        ${textAnswer.length|| role === 'psych' ? 'answers-wrapper__confirm-btn_active' : ''}`}
            />
        </div>
    )
}