import React, {useCallback, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Layout } from '../../components/Layout'
import { BackButton } from '../../components/BackButton'
import search from '../../static/img/ic-search.svg'

export const Schools = () => {
    const [schools, setSchools] = useState([])
    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState([]);
    const [schoolsCities, setSchoolsCities] = useState([]);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [afterSearch, setAfterSearch] = useState([]);
    function searchHandler(e){
        setSearchField(e.target.value);
    }

    useEffect(() => {
        (async () => {
            const { data } = await axios.get('schools')
            setSchools(data)
            setFilteredSchools(data)
        })()
    }, [])

    useEffect(() => {
        if(schools.length > 0){
            for(let i =0; i < schools.length; i++){
                let isAdd = true;
                schoolsCities.map((item) => {
                    if(schools[i].city === item){
                        isAdd = false;
                    }
                })
                if(isAdd){
                    let array = schoolsCities;
                    let filtersArray = filters;
                    array.push(schools[i].city);
                    filtersArray.push({
                        city: schools[i].city,
                        active: false
                    })
                    setSchoolsCities(array)
                    setFilters(filtersArray)
                }
            }
        }
    }, [schools]);

    useEffect(() => {
        let length = 0;
        filters.map((filter) => {
            if(filter.active){
                length++;
            }
        })
        if(length > 0){
            let filteredArray = [];
            schools.map((school) => {
                filters.map((filter) => {
                    if((school.city === filter.city) && filter.active){
                        filteredArray.push(school);
                    }
                })
            })
            setFilteredSchools(filteredArray);
        } else {
            setFilteredSchools(schools);
        }



    }, [filters])

    useEffect(() => {
        console.log(filteredSchools)
        if(searchField.length > 0){
            let newSchools = [];
            for(let school of filteredSchools){
                let name = school.name;
                let slicedName = name.slice(0, searchField.length)
                let city = school.city;
                let slicedCity = city.slice(0, searchField.length)
                console.log(slicedCity + "....." + searchField)
                if(slicedName.toLowerCase() === searchField.toLowerCase() || slicedCity.toLowerCase() === searchField.toLowerCase()){
                    newSchools.push(school)
                }
            }
            setAfterSearch(newSchools)

        } else {
            setAfterSearch(filteredSchools)
        }
    }, [searchField, filteredSchools])

    if(isFilters){
        return <SchoolFilters setFilters={setFilters} filters={filters} setIsFilters={setIsFilters}/>
    }
    
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Школы</p>
                    <button
                        onClick={() => {setIsFilters(true)}}
                        className={`icon-btn page__icon-btn page__icon-btn_right icon-btn_filter`} >
                    </button>
                </header>
                <div className="school-page__wrapper">
                    <div className="school-page__search">
                        <img src={search}/>
                        <input onChange={searchHandler} value={searchField} placeholder="Поиск"/>
                    </div>

                    <div className="list">
                        {afterSearch.map(school =>
                            <Link
                                key={school._id}
                                className="list__item"
                                to={location => `${location.pathname}/${school._id}/classes`}>
                            <p>{school.name}</p>
                                <p className="list__desc">
                                    {school.city}
                                </p>
                            </Link>
                        )}
                    </div>
                </div>
                <Link
                    to={`/schools/create`}
                    className="main-btn">
                    Добавить школу
                </Link>
            </div>
        </Layout>
    )
}
export const SchoolFilters = (props) => {

    function changeFilterActive(id){
        let array = [...props.filters];
        array[id].active = !props.filters[id].active;
        props.setFilters(array)
    }
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={() => {props.setIsFilters(false);}}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                    <p className="page__title">Фильтр</p>
                </header>
                <div className="list">
                    <h3>Выберите город</h3>
                    {props.filters.map((filter, orderId) =>{
                            return <div
                                key={'filter' + orderId}
                                className="list__checkbox">
                                <input
                                    type="checkbox"
                                    id={'filter' + orderId}
                                    name={filter.city}
                                    onChange={() => changeFilterActive(orderId)}
                                    checked={filter.active}
                                />

                                <label htmlFor={'filter' + orderId}>
                                    <div>
                                        <p>{filter.city}</p>
                                    </div>
                                </label>
                            </div>
                        }
                    )}
                </div>
            </div>
        </Layout>
    )
}