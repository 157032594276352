import React from "react";
import {Layout} from "../components/Layout";
import {BackButton} from "../components/BackButton";
import {Link} from "react-router-dom";
import {FaPen} from "react-icons/fa";
import info from "../static/img/ic-info-black.svg"
import {useSelector} from "react-redux";

export default function WorkWithMethods(){
    const { role } = useSelector(state => state.auth)
    return(
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">
                        Инструкция
                    </p>
                </header>
                <div className="workWithMethods-page__wrapper">
                    <h3>Инструкция</h3>
                    {role === 'psych' ?
                        <p>
                            {/*Добрый день, Коллеги!*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Вашему вниманию предлагается IT платформа TestU.online , разработанная компанией “Лаборатория Систем Саморазвития” на базе Инновационного центра Сколково.<br/>*/}
                            {/*По запросу Вы можете ознакомиться с презентацией, где описана концепция платформы, которая поможет качественно быстро и эффективно проводить процесс психодиагностики подростков в Вашем учреждении.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Мы предлагаем Вам провести массовые и одиночные тестирования в ваших учреждениях, посмотреть вывод результатов, рекомендаций после проведения тестирования и дополнительного функционала.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Специально под задачи диспансеризации мы адаптировали наш продукт. В базовом решении в комплекс психодиагностики входят 18 методик для выявления 13 актуальных проблем подростков.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Чтобы начать пользоваться TestU, перейдите по ссылке <a href="https://dispan.testu.online/">https://dispan.testu.online/</a> . После нажмите на желтую кнопку “Для психолога” по центру экрана. Введите в поля логин и пароль, которые были вам предоставлены на этапе регистрации в системе.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Когда вы войдете в личный кабинет, вам ннеобходимо добавить свою школу. Для этого нажмите на “Школы” и далее нажмите на синюю кнопку “Добавить школу” внизу экрана и внесите данные о школе, затем нажмите на синюю кнопку “Добавить школу” внизу экрана.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Далее нажмите на школу, которую добавили. Добавьте нужные классы, для этого нажмите на синюю кнопку “Добавить класс” внизу экрана, заполните данные о классе и нажмите на синюю кнопку “Добавить класс” внизу экрана. (См. 5 пункт для психолога).*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*После для каждого класса добавьте учеников, для этого нажмите на кнопку с черным значком QR кода справа от класса.<br/>*/}
                            {/*Вам откроется большой QR-код, для регистрации учеников в системе - достаточно показать учащимся QR код со своего компьютера или вывести его на электронную доску через проектор.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*В существующем функционале продукта под нужды диспансеризации были добавлены 4 методики:*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*1. Методики для прохождения родителями:<br/>*/}
                            {/*<br/>*/}
                            {/*<span style={{fontWeight: 600}}>- Скрининговая анкета для родителей по выявлению риска возникновения нарушений психического развития у детей раннего возраста (М.В. Иванов, Н.В. Симашкова, Г.В. Козловская)</span><br/>*/}
                            {/*<span style={{fontWeight: 600}}>- Родительская анкета по выявлению ПТСР у детей</span>*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*2. Методики для прохождения подростками:*/}

                            {/*- Опрос 1 (<span style={{fontWeight: 600}}>Методика Ч.Д. Спилбергера на выявление личностной и ситуативной тревожности (адаптирована на русский язык Ю.Л.Ханиным)</span>)<br/>*/}
                            {/*- Опрос 2 (<span style={{fontWeight: 600}}>Опросник депрессии Бека (Для подростков старшего школьного возраста)</span>)*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*В зависимости от группы респондентов (сами подростки \ родители подростков) необходимо на экране QR кода выбрать соответствующий и показать его респондентам для сканирования мобильным телефоном и регистрации в системе.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*<span style={{fontWeight: 600}}>ВАЖНО: Если QR код показывается родителю, то в системе он всё равно заполняет все данные по своему ребенку, включая этап регистрации. Если у одного родителя несколько детей, то он регистрирует аккаунт на каждого ребенка в отдельности.</span>*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*При регистрации необходимо заполнить все поля; по придуманному паролю и логину в будущем для данного ученика осуществляется вход на платформу, поэтому эти данные необходимо запомнить.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Также вы можете прислать ссылку своим ученикам по почте (она находится на странице с большим QR кодом конкретного класса), чтобы они зарегистрировались на сайте удаленно.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*После того, как ученики зарегистрируются в системе – им сразу будут предложены соответствующие методики для прохождения в разделе «Тесты». Как только ученики пройдут тесты – результаты сразу же будут доступны для специалиста.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Вы сможете увидеть результаты через раздел «Результаты психодиагностики», где вы можете последовательно выбрать группу респондентов для которой хотите видеть результат, включая фильтрацию по конкретному тесту, шкале и уровню.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Результаты в данный момент можно смотреть только по каждому ученику в отдельности, поэтому наша рекомендация – это настраивать фильтры по уровням чтобы выделять список подростков в группе риска, а затем на странице каждого ученика отщелкивать галочку «Группа риска» в соответствующем разделе.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*В будущем мы адаптируем решение статистическим модулем, который позволяет выводить срез результата по всему классу или школе, сейчас важнее определить точечно подростков, которым необходима помощь.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Результаты тестирования не доступны для подростков.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*У подростков и их родителей существует функция «Хочу поговорить», кнопка расположена в личном кабинете ученика на главной странице. При нажатии на неё – вы как специалист получите уведомление у себя в личном кабинете, что определенный подросток или родитель хотел бы что-то обсудить. Этот функционал не предназначен для экстренной помощи, но в рамках плановых запросов от подростков и родителей позволяет им легко заявить о своем запросе на беседу с психологом.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*«Блок заметки» - вы можете осуществлять записи по каждому ученику, если это необходимо. Данный блок не доступен для учеников. Рекомендуем в данном блоке в первой заметке записать логин и пароль ученика, особенно у подростков из группы риска, чтобы в случае потери доступа вы могли подсказать его ученикам.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*«Блок рекомендации» - вы можете осуществлять записи в формате рекомендации по каждому ученику, если это необходимо. Данный блок доступен для учеников.*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*<span style={{fontWeight: 600}}>В блоке «Настройки» просьба не менять ваш логин и почту.</span>*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*По любым вопросам технического характера, сбоев вы можете обращаться*/}
                            {/*по почте <a href="mailto:help@testu.online">help@testu.online</a> или по телефону: 89605217575 , Валерий*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*По вопросам предложений развития платформы и важных для вас необходимых функций вы можете обращаться по почте – school@testu.online*/}




                            Добрый день, Коллеги!
                            <br/><br/>
                            Вашему вниманию предлагается IT платформа TestU.online , разработанная компанией “Лаборатория Систем Саморазвития” на базе Инновационного центра Сколково.
                            По запросу Вы можете ознакомиться с презентацией, где описана концепция платформы, которая поможет качественно быстро и эффективно проводить процесс психодиагностики подростков в Вашем учреждении.
                            <br/><br/>
                            Мы предлагаем Вам провести массовые и одиночные тестирования в ваших учреждениях, посмотреть вывод результатов, рекомендаций после проведения тестирования и дополнительного функционала.
                            <br/><br/>
                            Специально под задачи диспансеризации мы адаптировали наш продукт. В базовом решении в комплекс психодиагностики входят 18 методик для выявления 13 актуальных проблем подростков.
                            <br/><br/>
                            Чтобы начать пользоваться TestU, перейдите по ссылке <a href="https://testu.minzdrav.gov.ru/">https://testu.minzdrav.gov.ru/</a> . После нажмите на желтую кнопку “Для психолога” по центру экрана. Введите в поля логин и пароль, которые были вам предоставлены на этапе регистрации в системе.
                            Когда вы войдете в личный кабинет, вам ннеобходимо добавить свою школу. Для этого нажмите на “Школы” и далее нажмите на синюю кнопку “Добавить школу” внизу экрана и внесите данные о школе, затем нажмите на синюю кнопку “Добавить школу” внизу экрана.
                            Далее нажмите на школу, которую добавили. Добавьте нужные классы, для этого нажмите на синюю кнопку “Добавить класс” внизу экрана, заполните данные о классе и нажмите на синюю кнопку “Добавить класс” внизу экрана. (См. 5 пункт для психолога).
                            <br/><br/>
                            После для каждого класса добавьте учеников, для этого нажмите на кнопку с черным значком QR кода справа от класса.
                            Вам откроется большой QR-код, для регистрации учеников в системе - достаточно показать учащимся QR код со своего компьютера или вывести его на электронную доску через проектор.  В существующем функционале продукта под задачи диспансеризации были применены 4 методики:  1. Методики для прохождения родителями: <br/>
                            <span style={{fontWeight: 600}}>- Скрининговая анкета для родителей по выявлению риска возникновения нарушений психического развития у детей раннего возраста (М.В. Иванов, Н.В. Симашкова, Г.В. Козловская)</span>
                            <br/><br/>
                            <span style={{fontWeight: 600}}>- Родительская анкета по выявлению ПТСР у детей</span>
                            <br/><br/>
                            2. Методики для прохождения подростками:
                            <br/><br/>
                            - Опрос 1 (<span style={{fontWeight: 600}}>Методика Ч.Д. Спилбергера на выявление личностной и ситуативной тревожности (адаптирована на русский язык Ю.Л.Ханиным)</span>)
                            <br/><br/>
                            - Опрос 2 (<span style={{fontWeight: 600}}>Опросник депрессии Бека (Для подростков старшего школьного возраста)</span>)
                            <br/><br/>
                            В зависимости от группы респондентов (сами подростки \ родители подростков) необходимо на экране QR кода выбрать соответствующий и показать его респондентам для сканирования мобильным телефоном и регистрации в системе.
                            <br/><br/>
                            <span style={{fontWeight: 600}}>ВАЖНО: Если QR код показывается родителю, то в системе он всё равно заполняет все данные по своему ребенку, включая этап регистрации. Если у одного родителя несколько детей, то он регистрирует аккаунт на каждого ребенка в отдельности.</span>
                            <br/><br/>
                            При регистрации необходимо заполнить все поля; по придуманному паролю и логину в будущем для данного ученика осуществляется вход на платформу, поэтому эти данные необходимо запомнить.
                            <br/><br/>
                            Также вы можете прислать ссылку своим ученикам по почте (она находится на странице с большим QR кодом конкретного класса), чтобы они зарегистрировались на сайте удаленно.
                            <br/><br/>
                            После того, как ученики зарегистрируются в системе – им сразу будут предложены соответствующие методики для прохождения в разделе «Тесты». Как только ученики пройдут тесты – результаты сразу же будут доступны для специалиста.
                            <br/><br/>
                            Вы сможете увидеть результаты через раздел «Результаты психодиагностики», где вы можете последовательно выбрать группу респондентов для которой хотите видеть результат, включая фильтрацию по конкретному тесту, шкале и уровню.
                            <br/><br/>
                            Результаты в данный момент можно смотреть только по каждому ученику в отдельности, поэтому наша рекомендация – это настраивать фильтры по уровням чтобы выделять список подростков в группе риска, а затем на странице каждого ученика отщелкивать галочку «Группа риска» в соответствующем разделе.  В будущем мы адаптируем решение статистическим модулем, который позволяет выводить срез результата по всему классу или школе, сейчас важнее определить точечно подростков, которым необходима помощь.
                            <br/><br/>
                            Результаты тестирования не доступны для подростков.
                            <br/><br/>
                            У подростков и их родителей существует функция «Хочу поговорить», кнопка расположена в личном кабинете ученика на главной странице. При нажатии на неё – вы как специалист получите уведомление у себя в личном кабинете, что определенный подросток или родитель хотел бы что-то обсудить. Этот функционал не предназначен для экстренной помощи, но в рамках плановых запросов от подростков и родителей позволяет им легко заявить о своем запросе на беседу с психологом.
                            <br/><br/>
                            «Блок заметки» - вы можете осуществлять записи по каждому ученику, если это необходимо. Данный блок не доступен для учеников. Рекомендуем в данном блоке в первой заметке записать логин и пароль ученика, особенно у подростков из группы риска, чтобы в случае потери доступа вы могли подсказать его ученикам.
                            <br/><br/>
                            «Блок рекомендации» - вы можете осуществлять записи в формате рекомендации по каждому ученику, если это необходимо. Данный блок доступен для учеников.
                            <br/><br/>
                            <span style={{fontWeight: 600}}>В блоке «Настройки» просьба не менять ваш логин и почту.</span>
                            <br/><br/>
                            <br/>
                            По любым вопросам технического характера, сбоев вы можете обращаться
                            по почте <a href="mailto:help@testu.online">help@testu.online</a> или по телефону: 89605217575 , Валерий
                            <br/><br/>
                            <br/>
                            По вопросам предложений развития платформы и важных для вас необходимых функций вы можете обращаться по почте – <a href="mailto:school@testu.online">school@testu.online</a>
                            <br/><br/>
                            Использованы следующие методики, с следующим разделением по уровням и баллами. Подробнее вы можете найти информацию в профильной литературе.
                            <br/><br/>
                            Родительская анкета - 6 шкал - 3 уровня (0-30%, 30-50%, 50-100%)
                            <br/><br/>
                            Скрининговая анкета - 2 шкалы - 2 уровня (Важные вопросы: 0-0, 1-9; Базовые вопросы: 0-3 , 4-26)
                            <br/><br/>
                            Опрос 1 - 2 шкалы - 2 уровня (0-30, 31-44, 45-80)
                            <br/><br/>
                            Опрос 2 - 1 шкала - 4 уровня (0-9, 10-19, 20-22, 23-39)
                        </p>
                        : ''}
                    {role === 'pupil' ?
                        <p>Добрый день !
                            <br/>
                            <br/>
                            Вашему вниманию предлагается продукт, который позволяет быстро и эффективно проводить различного типа анкетирования.<br/>
                            <br/>
                            Советуем вам запомнить логин и пароль, придуманные вами для входа в систему, можно записать их в блоке «Контакты родителей»<br/>
                            <br/>
                            Пожалуйста, в настройках, заполните в блоке «Персональные данные» раздел «Контакты родителей» в свободном стиле, то есть обозначьте город нахождения родителей и их контактные телефон, а также Фамилию Имя Отчество.<br/>
                            <br/>
                            После регистрации в системе для вас доступны 2 теста, каждый из которых необходимо пройти.<br/>
                            <br/>
                            После прохождения тестирования, если у вас есть запрос на связь со специалистом – вы можете нажать кнопку «Хочу поговорить», данный функционал предназначен не для экстренного решения проблем, а для того, чтобы у вас была возможность безопасно заявить о наличии запроса на разговор.<br/>
                            <br/>
                            В блоке «Рекомендации» специалист вашего учреждения сможет формировать вам индивидуальные рекомендации для вас, в этом разделе вы сможете с ними ознакомиться.<br/>
                            <br/>
                            Мы работаем над улучшением продукта и добавлением полезных функций. Будем рады обратной связи, если у вас есть идеи как можно улучшить наш продукт: добавить интересные контент по теме, методики по профориентации или что-то дополнительное.<br/>
                            <br/>
                            Просьба не менять логин в разделе «Безопасность и вход»<br/>
                            <br/>
                            По любым вопросам технического характера, сбоев вы можете обращаться
                            по почте <a href="mailto:help@testu.online">help@testu.online</a><br/>
                            <br/>
                            <br/>
                            По вопросам предложений развития платформы и важных для вас необходимых функций вы можете обращаться по почте – <a href="mailto:school@testu.online">school@testu.online</a></p>
                        :''}


                </div>
            </div>
        </Layout>
    )
}