import React, { useState, useEffect } from "react"
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { BackButton } from "../../components/BackButton"
import { Range } from "../../components/Range"
import { Layout } from "../../components/Layout"
import search from "../../static/img/ic-search.svg";


export const StatisticClasses = () => {
    const schoolId = useParams().id
    const [classes, setClasses] = useState([])
    const [searchField, setSearchField] = useState('');
    const [afterSearch, setAfterSearch] = useState([]);
    function searchHandler(e){
        setSearchField(e.target.value);
    }

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`statistic/schools/${schoolId}`)
                setClasses(data)
            })()
        }
    }, [schoolId])
    console.log(classes)

    useEffect(() => {
        if(searchField.length > 0){
            let newClasses = [];
            for(let classItem of classes){
                let name = classItem.number + classItem.letter;
                let slicedName = name.slice(0, searchField.length)
                if(slicedName.toLowerCase() === searchField.toLowerCase()){
                    newClasses.push(classItem)
                }
            }
            setAfterSearch(newClasses)

        } else {
            setAfterSearch(classes)
        }
    }, [searchField, classes])
    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">
                        {classes[0]?.school || 'Статистика'}
                    </p>
                </header>
                <div>
                    <div className="school-page__search">
                        <img src={search}/>
                        <input onChange={searchHandler} value={searchField} placeholder="Поиск"/>
                    </div>
                    {afterSearch.map(item => (
                        <Link
                            key={item._id}
                            to={`/statistic/classes/${item._id}`}
                            className="statistic-condition">
                            <p style={{marginBottom:20}} className="statistic-condition__title">
                                {item.number} {item.letter}
                            </p>
                            <p style={{textAlign: 'left'}} className="statistic-condition__members">
                                Учеников: {item.classLength}
                            </p>
                        </Link>
                    ))}
                </div>
            </div>
        </Layout>
    )
}