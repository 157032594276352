import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { Login } from './pages/Login'
import { Register } from './pages/Register'

import { Schools } from './pages/schools/Schools'
import { SchoolForm } from './pages/schools/SchoolForm'
import { Classes } from './pages/classes/Classes'
import { ClassForm } from './pages/classes/ClassForm'
import { Code } from './pages/Code'
import { Pupils } from './pages/Pupils'

import { StatisticSchools } from './pages/statistic/StatisticSchools'
import { StatisticClasses } from './pages/statistic/StatisticClasses'
import { StatisticPupils } from './pages/statistic/StatisticPupils'
import { StatisticPupil } from './pages/statistic/StatisticPupil'

import { Meetings } from './pages/meetings/Meetings'
import { MeetingForm } from './pages/meetings/MeetingForm'

import { TestsPage } from './pages/TestsPage'
import { SolutionPage } from './pages/SolutionPage'
import { TestPage } from './pages/TestPage'
import { ProfilePage } from './pages/ProfilePage'

import { CreateTestPage } from './pages/CreateTestPage'
import { SettingsPage } from './pages/SettingsPage'
import { SecurityPage } from './pages/SecurityPage'
import { LandingPage } from './pages/LandingPage'

import { PupilPage } from './pages/PupilPage'
import { AdvicesPage } from './pages/AdvicesPage'
import { NotesPage } from './pages/NotesPage'
import { AlarmsPage } from './pages/AlarmsPage'
import { LosePassPage } from './pages/LosePassPage'
import { CategoriesPage } from './pages/CategoriesPage'
import { StatisticFilters } from './pages/statistic/StatisticFilters'
import {CreateTestFilters} from "./pages/CreateTestFilters";
import WorkWithMethods from "./pages/WorkWithMethods";
import ProblemsPage from "./pages/ProblemsPage";
import ProblemPage from "./pages/ProblemPage";
import {RiskGroups} from "./pages/RiskGroups";


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact><LandingPage /></Route>
        <Route path="/psych/lose" ><LosePassPage role="psych" /></Route>
        <Route path="/pupil/lose" ><LosePassPage role="pupil" /></Route>
        <Route path="/psych/login"><Login role='psych' /></Route>
        <Route path="/psych/register"><Register role='psych' /></Route>
        <Route path="/pupil/login" exact><Login role='pupil' /></Route>
        <Route path="/pupil/login/:classId"><Login role='pupil' /></Route>
        <Route path="/pupil/:classId/child"><Register role='pupil' isChild={true} /></Route>
        <Route path="/pupil/:classId/parent"><Register role='pupil' isChild={false} /></Route>

        <Route path="/dashboard" exact><ProfilePage /></Route>

        <Route path="/schools" exact><Schools /></Route>
        <Route path="/schools/create" exact><SchoolForm /></Route>
        <Route path="/schools/:id" exact><SchoolForm /></Route>
        <Route path="/schools/:id/classes" exact><Classes /></Route>
        <Route path="/schools/:schoolId/classes/create"><ClassForm /></Route>

        <Route path="/classes/:id" exact><ClassForm /></Route>
        <Route path="/classes/:id/code"><Code /></Route>
        <Route path="/classes/:id/pupils"><Pupils /></Route>

        <Route path="/statistic/filters"><StatisticFilters /></Route>
        <Route path="/statistic/schools" exact><StatisticSchools /></Route>
        <Route path="/statistic/schools/:id"><StatisticClasses /></Route>
        <Route path="/statistic/classes/:id" exact><StatisticPupils /></Route>
        <Route path="/statistic/classes/:classId/filters"><StatisticFilters /></Route>
        <Route path="/statistic/pupils/:id" exact><StatisticPupil /></Route>=
        <Route path="/statistic/pupils/:pupilId/filters"><StatisticFilters /></Route>=

        <Route path="/pupils/:id" exact><PupilPage /></Route>
        <Route path="/pupils/:id/categories/"><CategoriesPage /></Route>
        <Route path="/riskGroups" exact><RiskGroups /></Route>

        <Route path="/pupils/:pupilId/meetings/" exact><Meetings /></Route>
        <Route path="/pupils/:pupilId/meetings/create"><MeetingForm /></Route>
        <Route path="/pupils/:pupilId/meetings/:meetingId"><MeetingForm /></Route>

        <Route path="/meetings" exact><Meetings /></Route>
        <Route path="/meetings/schools" exact><Schools /></Route>
        <Route path="/meetings/schools/:id/classes" exact><Classes /></Route>
        <Route path="/meetings/classes/:id/pupils" exact><Pupils /></Route>
        <Route path="/meetings/:meetingId"><MeetingForm /></Route>

        <Route path="/advices" exact><AdvicesPage /></Route>

        <Route path="/notes" exact><NotesPage /></Route>

        <Route path="/alarms" exact><AlarmsPage /></Route>

        <Route path="/instruction" exact><WorkWithMethods/></Route>
        <Route path="/tests" exact><TestsPage /></Route>
        <Route path="/tests/info/:id" exact><TestsPage /></Route>
        <Route path="/tests/instruction/:id" exact><TestsPage /></Route>
        <Route path="/tests/create" exact><CreateTestPage /></Route>
        <Route path="/tests/create/:classId/filters"><CreateTestFilters /></Route>
        <Route path="/tests/:testId/:conditionId/"><TestPage /></Route>
        <Route path="/solutions/:id"><SolutionPage /></Route>

        <Route path="/settings/" exact><SettingsPage /></Route>
        <Route path="/settings/security" exact><SecurityPage /></Route>

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  )
}

export default App