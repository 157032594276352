import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FaPen } from 'react-icons/fa'
import { FiArchive } from "react-icons/fi"
import { BackButton } from '../components/BackButton'
import { Layout } from '../components/Layout'
import axios from 'axios'
import trashImg from '../static/img/trash.svg'
import {useDispatch, useSelector} from "react-redux";
import {getPupils} from "../redux/actions/setPupilsAction";
import search from "../static/img/ic-search.svg";

const styles = {
    button: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        top: '1rem',
        right: '1rem',
        width: '2rem',
        height: '2rem',
        background: '#f4f7f9',
        borderRadius: '7px',
    },
    h4: {
        color: '#122443',
        fontSize: '20px',
        letterSpacing: '0.4px',
        marginBottom: '1rem',
        fontWeight: 'normal'
    },
    list: {
        flexGrow: 'initial',
        marginBottom: '3rem'
    }
}

export const RiskGroups = () => {

    let touchstartX = 0;
    let currentTouch = 0;
    const history = useHistory()
    const classId = useParams().id
    const [pupils, setPupils] = useState([])
    const pupilsLoaded = useSelector(state => state.pupils)
    const [isFilters, setIsFilters] = useState(false);
    const [filteredPupils, setFilteredPupils] = useState([]);
    const [filters, setFilters] = useState({
        city: [],
        school: [],
        class: []
    });
    const [citiesList, setCitiesList] = useState([]);
    const [schoolsList, setSchoolsList] = useState([]);
    const [classesList, setClassesList] = useState([]);
    const [searchField, setSearchField] = useState('');
    const [searchedPupils, setSearchedPupils] = useState([]);


    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPupils());
    }, [])
    useEffect(() => {
        if(pupilsLoaded.pupilsData){
            let currentArray = [];
            pupilsLoaded.pupilsData.map((pupil) => {
                if(pupil.categories.length > 0){
                    currentArray.push(pupil);
                }
            })
            setPupils(currentArray);
        }
    }, [pupilsLoaded]);

    const archive = async e => {
        e.stopPropagation()
        const pupil = pupils.filter(p => p._id === e.currentTarget.id)[0]
        if (pupil && window.confirm('Подтвердите внесение изменений')) {
            const { data } = await axios.post(`pupil/${e.currentTarget.id}`, {
                isArchive: !pupil.isArchive,
                categories: pupil.categories
            })

            setPupils(pupils.map(pupil => pupil._id === data._id ? data : pupil))
        }
    }
    let isTouched = false;
    let isFixed = false;
    function handleTouchStart(event){
        if(event.touches){
            touchstartX = event.touches[0].screenX;
        } else {
            touchstartX = event.screenX;
        }
        isTouched = true;
    }
    function handleTouchEnd(event){
        isTouched = false;
        if(touchstartX - currentTouch < 100 && !isFixed){
            isFixed = false;
            event.currentTarget.style.marginLeft = 0 + 'px';
        }
    }
    function handleMove(event){
        if(event.touches){
            currentTouch = event.touches[0].screenX;
        } else {
            currentTouch = event.screenX;
        }
        if(currentTouch < touchstartX && isTouched && !isFixed){
            if(touchstartX - currentTouch < 100){
                event.currentTarget.style.marginLeft = ((touchstartX - currentTouch) * (-1)) + 'px';
            } else {
                isFixed = true;
            }
        }
    }



    useEffect(() => {
        let cities = [];
        let classes = [];
        let schools = [];
        for(let pupil of pupils){
            const indexCity = cities.indexOf(pupil.city)
            if (!(indexCity > -1)) {
                cities.push(pupil.city)
            }

            const indexClasses = classes.indexOf(pupil.class.number.toString() + pupil.class.letter)
            if (!(indexClasses > -1)) {
                classes.push(pupil.class.number.toString() + pupil.class.letter)
            }

            const indexSchools = schools.indexOf(pupil.school)
            if (!(indexSchools > -1)) {
                schools.push(pupil.school)
            }
        }
        setCitiesList(cities);
        setClassesList(classes);
        setSchoolsList(schools);
    }, [pupils]);

    useEffect(() => {

        if(pupils.length > 0){
            let beforeFiltration = [...pupils];
            if(filters.city.length > 0){
                let newPupils = [];

                for(let pupil of beforeFiltration){
                    for(let filter of filters.city){
                        if(pupil.city === filter){
                            newPupils.push(pupil)
                        }
                    }
                }
                beforeFiltration = newPupils;
            }
            if(filters.school.length > 0){
                let newPupils = [];
                for(let pupil of beforeFiltration){
                    for(let filter of filters.school) {
                        if (pupil.school === filter) {
                            newPupils.push(pupil)
                        }
                    }
                }
                beforeFiltration = newPupils;
            }
            if(filters.class.length > 0){
                let newPupils = [];
                for(let pupil of beforeFiltration){
                    for(let filter of filters.class){
                        if((pupil.class.number + pupil.class.letter) === filter){
                            newPupils.push(pupil)
                        }
                    }
                }
                beforeFiltration = newPupils;
            }


            setFilteredPupils(beforeFiltration);
        }

    }, [filters, pupils]);

    function setNewFilter(type, filterValue){
        let isAdd = false;
        switch (type){
            case 'city':
                const indexCity = filters.city.indexOf(filterValue)
                if (indexCity > -1) {
                    let newCity = filters.city;
                    newCity.splice(indexCity, 1);
                    setFilters({
                        ...filters,
                        city: newCity
                    })
                } else {
                    let newCity = filters.city;
                    newCity.push(filterValue);
                    setFilters({
                        ...filters,
                        city: newCity
                    })
                }
                break;
            case 'school':
                const indexSchool = filters.school.indexOf(filterValue)
                if (indexSchool > -1) {
                    let newSchool = filters.school;
                    newSchool.splice(indexSchool, 1);
                    setFilters({
                        ...filters,
                        school: newSchool
                    })
                } else {
                    let newSchool = filters.school;
                    newSchool.push(filterValue);
                    setFilters({
                        ...filters,
                        school: newSchool
                    })
                }
                break;
            case 'class':
                const indexClass = filters.class.indexOf(filterValue)
                if (indexClass > -1) {
                    let newClass = filters.class;
                    newClass.splice(indexClass, 1);
                    setFilters({
                        ...filters,
                        class: newClass
                    })
                } else {
                    let newClass = filters.class;
                    newClass.push(filterValue);
                    setFilters({
                        ...filters,
                        class: newClass
                    })
                }
                break;
        }

    }
    function searchHandler(e){
        setSearchField(e.target.value);
    }
    useEffect(() => {

        if(searchField && filteredPupils){
            let newSearched= []

            for(let pupil of filteredPupils){

                let name = pupil.name;
                let slicedName = name.slice(0, searchField.length)
                let lastName = pupil.surname;
                let slicedLast = lastName.slice(0, searchField.length)

                if(slicedName.toLowerCase() === searchField.toLowerCase() || slicedLast.toLowerCase() === searchField.toLowerCase()){
                    newSearched.push(pupil)
                }
            }

            setSearchedPupils(newSearched)
        } else {
            setSearchedPupils(filteredPupils)
        }
    }, [searchField, filteredPupils])

    if(isFilters){
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {setIsFilters(false);}}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />
                        <p className="page__title">Фильтр</p>
                    </header>
                    <div className="list">
                        <h3>Выберите город</h3>
                        {citiesList.map((city, orderId) =>{
                                return <div
                                    key={'filter' + orderId}
                                    className="list__checkbox">
                                    <input
                                        type="checkbox"
                                        id={'city_' + orderId}
                                        name={city}
                                        onChange={() => setNewFilter('city', city)}
                                        checked={filters.city.indexOf(city) > -1}
                                    />

                                    <label htmlFor={'city_' + orderId}>
                                        <div>
                                            <p>{city}</p>
                                        </div>
                                    </label>
                                </div>
                            }
                        )}
                        <h3 style={{marginTop: 40}}>Выберите школу</h3>
                        {schoolsList.map((school, orderId) =>{
                                return <div
                                    key={'filter' + orderId}
                                    className="list__checkbox">
                                    <input
                                        type="checkbox"
                                        id={'school_' + orderId}
                                        name={school}
                                        onChange={() => setNewFilter('school', school)}
                                        checked={filters.school.indexOf(school) > -1}
                                    />

                                    <label htmlFor={'school_' + orderId}>
                                        <div>
                                            <p>{school}</p>
                                        </div>
                                    </label>
                                </div>
                            }
                        )}
                        <h3 style={{marginTop: 40}}>Выберите класс</h3>
                        {classesList.map((classItem, orderId) =>{
                                return <div
                                    key={'filter' + orderId}
                                    className="list__checkbox">
                                    <input
                                        type="checkbox"
                                        id={'class_' + orderId}
                                        name={classItem}
                                        onChange={() => setNewFilter('class', classItem)}
                                        checked={filters.class.indexOf(classItem) > -1}
                                    />

                                    <label htmlFor={'class_' + orderId}>
                                        <div>
                                            <p>{classItem}</p>
                                        </div>
                                    </label>
                                </div>
                            }
                        )}

                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">
                        Группы риска
                    </p>
                    <button
                        onClick={() => {setIsFilters(true)}}
                        className={`icon-btn page__icon-btn page__icon-btn_right icon-btn_filter`} >
                    </button>
                </header>
                <div className="school-page__search">
                    <img src={search}/>
                    <input onChange={searchHandler} value={searchField} placeholder="Поиск"/>
                </div>

                <div className="school-page__wrapper">
                    <div className="list gesturedZone" style={styles.list}>
                        {searchedPupils.filter(p => !p.isArchive).map(pupil =>
                            <div
                                key={pupil._id}
                                style={{
                                    position: 'relative'
                                }}
                                onClick={(e) => {
                                    // if(touchstartX === e.screenX){
                                    //     if(isFixed){
                                    //         e.currentTarget.style.marginLeft = 0 + 'px';
                                    //         isFixed = false
                                    //     } else {
                                    if (window.location.href.indexOf("/meetings/classes/") !== -1) {
                                        history.push(`/pupils/${pupil._id}/meetings/create`)
                                    } else {
                                        history.push(`/pupils/${pupil._id}`)
                                    }
                                    //     }
                                    // }
                                }}
                                // onTouchStart={handleTouchStart}
                                // onTouchEnd={handleTouchEnd}
                                // onMouseDown={handleTouchStart}
                                // onMouseMove={handleMove}
                                // onMouseUp={handleTouchEnd}
                                // onTouchMove={handleMove}
                                className="list__item">
                                <p>{pupil.name} {pupil.surname}</p>
                                <p className="list__desc">
                                    {pupil.psych?.name} {pupil.psych?.surname}
                                </p>
                                {/*<button*/}
                                {/*    id={pupil._id}*/}
                                {/*    onClick={archive}*/}
                                {/*    style={styles.button}>*/}
                                {/*    <FiArchive style={{ fontSize: '16px' }} />*/}
                                {/*</button>*/}
                                <div className="deleteStudent">
                                    <img src={trashImg}/>
                                </div>
                            </div>
                        )}
                    </div>
                    {/*<h4 style={styles.h4}>Архив</h4>*/}
                    {/*<div className="list">*/}
                    {/*    {pupils.filter(p => p.isArchive).map(pupil =>*/}
                    {/*        <div*/}
                    {/*            key={pupil._id}*/}
                    {/*            style={{*/}
                    {/*                position: 'relative'*/}
                    {/*            }}*/}
                    {/*            onClick={() => {*/}
                    {/*                if (window.location.href.indexOf("/meetings/classes/") !== -1) {*/}
                    {/*                    history.push(`/pupils/${pupil._id}/meetings/create`)*/}
                    {/*                } else {*/}
                    {/*                    history.push(`/pupils/${pupil._id}`)*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            className="list__item">*/}
                    {/*            <p>{pupil.name} {pupil.surname}</p>*/}
                    {/*            <p className="list__desc">*/}
                    {/*                {pupil.psych?.name} {pupil.psych?.surname}*/}
                    {/*            </p>*/}
                    {/*            <button*/}
                    {/*                id={pupil._id}*/}
                    {/*                onClick={archive}*/}
                    {/*                style={styles.button}>*/}
                    {/*                <FiArchive style={{ fontSize: '16px' }} />*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            </div>
        </Layout>
    )
}