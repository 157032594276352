import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { LOGIN, LOGOUT, UPDATE_USER } from '../types'


// export function login(role, user, token) {
//     return dispatch => {
//         dispatch({ type: LOGIN, payload: { role, user, token } })
//     }
// }


// export function logout() {
//     return dispatch => {
//         dispatch({ type: LOGOUT })
//     }
// }

export function updateUser(data) {
    return async (dispatch, getState) => {
        const { role, user } = getState().auth

        try {
            dispatch(startLoading())

            const json = await fetch(`/api/${role}/update`, {
                method: 'POST',
                body: JSON.stringify({ ...data, id: user._id }),
                headers: { 'Content-Type': 'application/json' }
            }, getState, dispatch)

            dispatch({ type: UPDATE_USER, payload: json[role] })
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'success', text: 'Изменения внесены успешно' }))

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function setUser(data, role) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const data = await fetch(`/api/${role}/user`, getState, dispatch)

            dispatch({ type: 'AUTH/UPDATE_USER', payload: data.psych })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}