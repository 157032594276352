import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton } from '../components/BackButton'
import { Layout } from '../components/Layout'
import { updateUser } from '../redux/actions/setUserActions'


const initialState = {
    email: ''
}

export const SecurityPage = () => {
    const dispatch = useDispatch()
    const {role} = useSelector(state => state.auth)
    const [form, setForm] = useState(initialState)
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if(user){
            if(user.email){
                setForm({
                    email: user.email
                })
            }
        }

    }, [user])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const submitHandler = e => {
        e.preventDefault()
        dispatch(updateUser(form))
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                </header>
                <div className="page__content">
                    <h3>Персональные данные</h3>

                    <div className="auth-page__input-group">
                        <div className="input-field">
                            <input
                                id="email"
                                type="text"
                                name="email"
                                value={form.email}
                                placeholder="Эл. почта"
                                onChange={changeHandler}
                            />
                            <label
                                htmlFor="email">
                                Эл. почта
                            </label>
                        </div>
                        <a href={`/${role}/lose`}>Забыли пароль?</a>
                    </div>

                </div>
                <button
                    className='main-btn'
                    disabled={form.email ? form.email === user.email : true}
                    onClick={submitHandler}>
                    Сохранить
                </button>
            </div>
        </Layout>
    )
}