import { useEffect } from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getAlarms, readAlarms } from '../redux/actions/setAlarmActions'
import { BackButton } from '../components/BackButton'
import { Layout } from '../components/Layout'


export const AlarmsPage = () => {
    const dispatch = useDispatch()
    const { alarms } = useSelector(state => state.alarm)

    useEffect(() => {
        dispatch(getAlarms())
        dispatch(readAlarms())
    }, [dispatch])


    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Помощь</p>
                </header>
                <div className="page__content">
                    <div className="list">
                        {alarms.map(alarm =>
                            <Link
                                key={alarm._id}
                                to={`/pupils/${alarm.pupil._id}`}
                                className={`list__item ${!alarm.readAt ? 'list__item_red' : ''}`}>
                                <p>{alarm.pupil.name} {alarm.pupil.surname}</p>
                                <p className="list__desc">
                                    <Moment
                                        locale="ru"
                                        format="HH:mm, DD MMM, YYYY">
                                        {alarm.createdAt}
                                    </Moment>
                                </p>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}